import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import Splash from "../../resources/images/wreath/splash.png";
import ProjectOverview from "../../resources/images/wreath/project-overview.png";
import Process from "../../resources/images/wreath/design-process.png";
import Mia from "../../resources/images/wreath/mia.jpg";
import JourneyMap from "../../resources/images/wreath/journey-map.jpg";
import Wireframes from "../../resources/images/wreath/wireframes.jpg";
import DWDelivery from "../../resources/images/wreath/digital-frame-delivery.png";
import DWSelect from "../../resources/images/wreath/digital-frame-select.png";
import DWHome from "../../resources/images/wreath/digital-frame-home.png";
import DWCheckout from "../../resources/images/wreath/digital-frame-checkout.png";
import DWExampleHome from "../../resources/images/wreath/digital-wireframe-example-home.png";
import DWExampleItems from "../../resources/images/wreath/digital-wireframe-example-items.png";
import LofiProto from "../../resources/images/wreath/lofi-prototype.png";
import UsabilityBefore1 from "../../resources/images/wreath/before-usability-study-availability.png";
import UsabilityBefore2 from "../../resources/images/wreath/before-usability-study-details.png";
import UsabilityAfter1 from "../../resources/images/wreath/after-usability-study-availability.png";
import UsabilityAfter2 from "../../resources/images/wreath/after-usability-study-details.png";
import HiFiProto from "../../resources/images/wreath/hifi-prototype.png";
import MockupSplash from "../../resources/images/wreath/mockup-splash.png";
import QuoteScreen from "../../resources/images/wreath/quote.png";
import ProSmallbiz from "../../resources/images/smallbiz-op.png";
import ProCareerAssist from "../../resources/images/career-op.png";

function Wreath() {
  return (
    <>
      {/* Hero Image */}
      <Container
        fluid
        style={{
          background:
            "rgb(212,238,221) linear-gradient(125deg, rgba(212,238,221,1) 0%, rgba(136,224,167,1) 100%)",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col xs={12}>
              <Image
                src={Splash}
                alt="career-assist splash image"
                className="img-fluid"
                style={{
                  display: "block",
                  margin: "72px auto",
                }}
              />
            </Col>
          </Row>
        </Container>
      </Container>

      {/* Project Overview */}
      <Container>
        <Row className="mt-2 pb-5">
          <Col>
            <span className="fw-bold">Project name:</span> Wreath
          </Col>
          <Col className="text-end">
            <span className="fw-bold">Tools used:</span> Figma, Affinity
            Designer, Google Workspace
          </Col>
        </Row>
        <Row className="mt-5 pb-5">
          <Col xs={3}>
            <Image
              src={ProjectOverview}
              alt="iPhone with career-assist app"
              className="img-fluid"
              style={{
                filter: "drop-shadow(5px 5px 4px #555)",
              }}
            />
          </Col>
          <Col className="offset-1">
            <h1 className="fw-bold">PROJECT OVERVIEW</h1>
            <p className="pt-2 fs-5">
              Wreath strives to offer its users the ability to easily order
              beautiful, affordable and personalised bouquets. Wreath
              accommodates all budgets as it offers users a wide spectrum of
              competitive pricing. Wreath targets individuals who desire
              bouquets that can be customised and are affordable.
            </p>
          </Col>
        </Row>

        <Row className="mt-5 px-2 pb-5">
          <Col
            style={{ backgroundColor: "#161616" }}
            className="text-light p-4 rounded-3"
          >
            <h3 className="fw-bold">The Problem</h3>
            <p>Individuals on a budget cannot afford highly priced bouquets.</p>
          </Col>
          <Col
            style={{ backgroundColor: "#92BA9A" }}
            className="p-4 mx-2 rounded-3"
          >
            <h3 className="fw-bold">The Goal</h3>
            <p>
              Design an App for Wreath’s florist that allows users to easily
              customise the contents of their bouquet to suit their budget.
            </p>
          </Col>
          <Col style={{ backgroundColor: "#D4EEDD" }} className="p-4 rounded-3">
            <h3 className="fw-bold">My Role</h3>
            <p>
              <span className="fw-bold">UX designer </span>
              designing an app for Wreath’s florist from conception to delivery.
            </p>
            <p>
              I was responsible for conducting interviews, paper and digital
              wireframing, low and high-fidelity prototyping, conducting
              usability studies, accounting for accessibility, iterating on
              designs and responsive design.
            </p>
          </Col>
        </Row>

        <Row className="mt-4 pb-5">
          <Col>
            <h3 className="fw-bold">The Design Process</h3>
            <Image
              src={Process}
              alt="The design process"
              className="img-fluid mt-1"
            />
          </Col>
        </Row>
      </Container>

      {/* Understanding the user */}
      <Container className="py-5" style={{ backgroundColor: "#2D2D2D" }} fluid>
        <Container className="text-light mt-2">
          <Row>
            <Col>
              <h1 className="fw-bold">UNDERSTANDING THE USER</h1>
              <h3 className="fw-bold">User Research Summary</h3>
              <p className="fs-5">
                I conducted interviews and created empathy maps to understand
                the users I’m designing for and their needs. A primary user
                group identified through research was individuals who could not
                afford to buy bouquets online due to the high and inflexible
                prices.
              </p>
              <p className="fs-5">
                This user group confirmed initial assumptions about Wreath
                customers. Research also revealed that the price of bouquets was
                not the only factor limiting users from buying bouquets online.
                Other user problems included allergies, personal preferences,
                personalisation, accessability and challenges that make it
                difficult to buy flowers in-store.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container
        className="pb-5 pt-5"
        style={{ backgroundColor: "#161616" }}
        fluid
      >
        <Container>
          <Row className="text-center">
            <Col className="text-light">
              <h3>
                <b>User Research: Pain Points</b>
              </h3>
            </Col>
          </Row>
          <Row className="text-center text-light mt-3 justify-content-center">
            <Col>
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#31703E" }}
              >
                1
              </div>
              <h5 className="fw-bold mt-2">Price</h5>
              <p className="fs-5">
                Individuals cannot afford the high prices charged for
                pre-arranged bouquets online.
              </p>
            </Col>
            <Col>
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#31703E" }}
              >
                2
              </div>
              <h5 className="fw-bold mt-2">Allergies</h5>
              <p className="fs-5">
                Users potentially purchase items that are harmful to their
                health.
              </p>
            </Col>
            <Col>
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#31703E" }}
              >
                3
              </div>
              <h5 className="fw-bold mt-2">Personalisation</h5>
              <p className="fs-5">
                Pre-arranged bouquets eliminate the option of customisation and
                personalisation.
              </p>
            </Col>
            <Col>
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#31703E" }}
              >
                4
              </div>
              <h5 className="fw-bold mt-2">Navigation</h5>
              <p className="fs-5">
                Users are overwhelmed by the amount of suggestions and
                categories offered to them.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Row>
          <Col>
            <h3 className="fw-bold mt-5 mb-3">Meet Mia</h3>
            <p className="h5 fst-italic">
              “I need the perfect floral arrangement to make my work environment
              feel pleasant, fresh and personal!”
            </p>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col lg={6}>
            <Image
              src={Mia}
              alt="Mia"
              className="img-fluid h-100 rounded-3"
              style={{ objectFit: "none", objectPosition: "center" }}
            />
          </Col>
          <Col lg={6}>
            <div
              className="p-4 rounded-3"
              style={{ backgroundColor: "#D4EEDD" }}
            >
              <h3 className="fw-bold">Problem statement</h3>
              <p>
                <span className="fw-bold">Mia</span> is a busy work-from-home
                employee who needs a service that will allow her to buy a
                customised bouquet of flowers because she is on a tight budget
                and wants to create a pleasant working environment that suits
                her personal preferences.
              </p>
              <p>
                <span className="fw-bold">Goals</span>
              </p>
              <ul>
                <li>
                  Create a pleasant work environment that ticks all the right
                  boxes.
                </li>
                <li>Inspire personal productivity.</li>
              </ul>
              <p>
                <span className="fw-bold">Frustrations</span>
              </p>
              <ul>
                <li>
                  “The cost of flowers is too high! I can’t afford them on my
                  tight budget.”
                </li>
                <li>“I want to personalise my bouquet.”</li>
                <li>“Most flowers don’t give off a pleasant aroma.”</li>
              </ul>
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h3 className="fw-bold">User journey map</h3>
            <p className="fs-5">
              Mapping Mia’s user journey revealed how helpful it would be for
              users to have access to a dedicated Wreath florist app.
            </p>
            <p className="fs-5">
              <span className="fw-bold">Goal:</span> An affordable way to order
              a customised bouquet of flowers online.
            </p>
            <Image
              src={JourneyMap}
              alt="Journey map"
              className="img-fluid mt-1"
            />
          </Col>
        </Row>
      </Container>

      {/* Starting the design */}
      <Container className="mt-5">
        <Row>
          <Col>
            <h1 className="fw-bold mt-5 mb-4">STARTING THE DESIGN</h1>
            <h3 className="fw-bold">Paper wireframes</h3>
            <p className="fs-5">
              By drafting iterations of each screen of the app on paper ensured
              that the elements that made it to digital wireframes would be
              well-suited to address user pain points. For the selection
              screens, I prioritized a easy and simple ordering process to help
              users save time and avoid confusion.
            </p>
          </Col>
        </Row>

        <Row className="mt-5 mb-2 justify-content-center">
          <Col>
            <Image
              src={Wireframes}
              alt="Paper wireframes of Smallbiz"
              className="img-fluid"
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h3 className="fw-bold">Digital wireframes</h3>
            <p className="fs-5">
              As the initial design phase continued, I made sure to base screen
              designs on feedback and findings from the user research. Being
              able to easily navigate in the App was a key user need to address
              in the designs in addition to equipping the app to work with
              assistive technologies.
            </p>
          </Col>
        </Row>
      </Container>
      <Container
        className="mt-5 py-3"
        fluid
        style={{
          backgroundColor: "#7EAF8E",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col>
              <Image
                src={DWDelivery}
                alt="wreath digital wireframe delivery"
                className="img-fluid py-5"
                style={{
                  filter: "drop-shadow(5px 5px 7px #000)",
                }}
              />
            </Col>
            <Col>
              <Image
                src={DWSelect}
                alt="wreath digital wireframe select"
                className="img-fluid py-5"
                style={{
                  filter: "drop-shadow(5px 5px 7px #000)",
                }}
              />
            </Col>
            <Col>
              <Image
                src={DWHome}
                alt="wreath digital wireframe home"
                className="img-fluid py-5"
                style={{
                  filter: "drop-shadow(5px 5px 7px #000)",
                }}
              />
            </Col>
            <Col>
              <Image
                src={DWCheckout}
                alt="wreath digital wireframe checkout"
                className="img-fluid py-5"
                style={{
                  filter: "drop-shadow(5px 5px 7px #000)",
                }}
              />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container>
        <Row className="mt-5 align-items-center">
          <Col xs={6}>
            <p className="fs-5 fw-bold">
              Obvious buttons make it easy for users to choose which action to
              take.
            </p>
            <p className="fs-5 fw-bold">
              Prepopulated elements save time for users who are in a hurry to
              place an order.
            </p>
          </Col>
          <Col xs={3} className="pt-4 pt-lg-0 offset-lg-3">
            <Image
              src={DWExampleHome}
              alt="Low-fidelity prototype"
              className="img-fluid"
              style={{
                filter: "drop-shadow(5px 5px 7px #000)",
              }}
            />
          </Col>
        </Row>
      </Container>

      <Container
        className="mt-5 py-5"
        fluid
        style={{
          backgroundColor: "#D4EEDD",
        }}
      >
        <Container>
          <Row className="align-items-center">
            <Col xs={3} className="pb-4 pb-lg-0">
              <Image
                src={DWExampleItems}
                alt="Low-fidelity prototype"
                className="img-fluid"
                style={{
                  filter: "drop-shadow(5px 5px 7px #000)",
                }}
              />
            </Col>
            <Col xs={6} className="offset-lg-3">
              <p className="fs-5 fw-bold">
                Larger “add to cart” buttons and images offer increased
                accessiblity.
              </p>
              <p className="fs-5 fw-bold">
                A floating navigation bar allows users to easily navigate
                through the App.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container>
        <Row className="mt-5 align-items-center pb-4">
          <Col xs={6}>
            <h3 className="fw-bold">Low-fidelity prototype</h3>
            <p className="fs-5">
              Using the completed set of digital wireframes, I created a
              low-fidelity prototype. The primary user flow I connected was
              customising and ordering a bouquet, so the prototype could be used
              in a usability study.
            </p>
            <a
              href="https://www.figma.com/proto/4F9z7wJ8o7jmL9qUXjACB8/Wreath-LoFi?page-id=0%3A1&node-id=107%3A103&viewport=241%2C48%2C0.6&scaling=scale-down&starting-point-node-id=107%3A103"
              target="_blank"
              rel="noreferrer"
              role="button"
              className="btn btn-lg btn-outline-dark mt-4"
            >
              View LoFi Prototype
            </a>
          </Col>
          <Col xs={4} className="pt-4 pt-lg-0 offset-2">
            <Image
              src={LofiProto}
              alt="Low-fidelity prototype"
              className="img-fluid"
              style={{
                filter: "drop-shadow(5px 5px 7px #000)",
              }}
            />
          </Col>
        </Row>

        <Row className="mt-5 mb-5">
          <Col className="text-dark">
            <h3 className="fw-bold">Usability Study: Findings</h3>
            <p className="fs-5">
              I conducted two usability studies and used insights from my
              finding to improve my designs. Insights from the first study
              helped guide the designs from wireframes to mock-ups. The second
              study made use of a high-fidelity prototype and revealed aspects
              of the mock-ups which needed improvement and refining.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="py-5" style={{ backgroundColor: "#161616" }} fluid>
        <Container>
          <Row className="text-center">
            <Col className="text-light">
              <h3>
                <b>User Research: Pain Points</b>
              </h3>
            </Col>
          </Row>
          <Row className="text-center text-light mt-3 justify-content-center">
            <Col>
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#31703E" }}
              >
                1
              </div>
              <p className="fs-5 mt-2">
                Users want to see a visual preview of the bouquet they designed.
              </p>
            </Col>
            <Col>
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#31703E" }}
              >
                2
              </div>
              <p className="fs-5 mt-2">
                Users want an easy and quick checkout process.
              </p>
            </Col>
            <Col>
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#31703E" }}
              >
                3
              </div>
              <p className="fs-5 mt-2">Users want a simplified navigation.</p>
            </Col>
            <Col>
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#31703E" }}
              >
                4
              </div>
              <p className="fs-5 mt-2">
                The delivery date selection option does not show days of the
                week.
              </p>
            </Col>
            <Col>
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#31703E" }}
              >
                5
              </div>
              <p className="fs-5 mt-2">
                Users want to edit the delivery date during the checkout
                process.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* Refining the design */}
      <Container>
        <Row>
          <Col>
            <h1 className="fw-bold mt-5 mb-4">REFINING THE DESIGN</h1>
            <h3 className="fw-bold">Mockups</h3>
            <p className="fs-5">
              The initial design allowed users to select a date from a rotation
              carousel but this proved to be a pain point as users could not
              determine which day of the week the date was. After the usability
              study, I changed the date selector to display a calendar.
            </p>
            <p className="fs-5">
              Before the usability study, users could not edit the delivery date
              in the checkout process. After the usability study I implemented a
              section where users could edit their order details.
            </p>
          </Col>
        </Row>
      </Container>

      <Container
        className="mt-5 py-5 text-light"
        fluid
        style={{
          backgroundColor: "#6DA07E",
        }}
      >
        <Container>
          <Row className="text-dark fs-2 justify-content-center">
            <Col>
              <h5 className="fw-bold">Before usability study</h5>
            </Col>
            <Col className="offset-lg-1">
              <h5 className="fw-bold">After usability study</h5>
            </Col>
          </Row>
          <Row className="mt-2 text-center text-dark fs-2 justify-content-center">
            <Col>
              <Image
                src={UsabilityBefore1}
                alt="Usability findings"
                className="img-fluid mt-1"
                style={{
                  filter: "drop-shadow(5px 5px 7px #000)",
                }}
              />
            </Col>
            <Col>
              <Image
                src={UsabilityBefore2}
                alt="Usability findings"
                className="img-fluid mt-1"
                style={{
                  filter: "drop-shadow(5px 5px 7px #000)",
                }}
              />
            </Col>
            <Col className="offset-lg-1">
              <Image
                src={UsabilityAfter1}
                alt="Usability findings"
                className="img-fluid mt-1"
                style={{
                  filter: "drop-shadow(5px 5px 7px #000)",
                }}
              />
            </Col>
            <Col>
              <Image
                src={UsabilityAfter2}
                alt="Usability findings"
                className="img-fluid mt-1"
                style={{
                  filter: "drop-shadow(5px 5px 7px #000)",
                }}
              />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container>
        <Row className="mt-5 align-items-center pb-4">
          <Col lg={6}>
            <h3 className="fw-bold">High-fidelity prototype</h3>
            <p className="fs-5">
              The iterated high-fidelity prototype presented simplified user
              flow for customising and purchasing a bouquet. It also addressed
              the need for users to edit their details before checkout .
            </p>
            <a
              href="https://www.figma.com/proto/eUWU4ys6cRpXBh9QMrucBZ/Wreath-Hi-Fi?page-id=358%3A2&node-id=358%3A181&viewport=241%2C48%2C0.19&scaling=scale-down&starting-point-node-id=358%3A181"
              target="_blank"
              rel="noreferrer"
              role="button"
              className="btn btn-lg btn-outline-dark mt-4"
            >
              View HiFi Prototype
            </a>
          </Col>
          <Col lg={6} className="pt-4 pt-lg-0">
            <Image
              src={HiFiProto}
              alt="Hi-fidelity prototype"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>

      <Container
        className="ps-5 mt-5"
        fluid
        style={{
          backgroundColor: "#6DA07E",
        }}
      >
        <Row className="justify-content-center">
          <Col className="g-0">
            <Image
              src={MockupSplash}
              alt="Smallbiz splash image"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="mt-5">
          <Col>
            <h3 className="fw-bold">Accessibility considerations</h3>
            <p className="fs-5">
              The following accessibility considerations were prioritized to
              ensure a smooth user experience for all users.
            </p>
          </Col>
        </Row>
        <Row className="text-center text-light mt-3 justify-content-center">
          <Col>
            <div
              className="p-4 me-1 rounded-3"
              style={{ backgroundColor: "#161616", height: "100%" }}
            >
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#31703E" }}
              >
                1
              </div>
              <p className="fs-5 mt-2">
                <b>Adding alt text to images</b> that can be read by screen
                readers for users who have vision impairments.
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="p-4 rounded-3"
              style={{ backgroundColor: "#161616", height: "100%" }}
            >
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#31703E" }}
              >
                2
              </div>
              <p className="fs-5 mt-2">
                Incorporate <b>accessible alternatives</b> to gestures for users
                who have mobility impairments.
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="p-4 ms-1 rounded-3"
              style={{ backgroundColor: "#161616", height: "100%" }}
            >
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#31703E" }}
              >
                3
              </div>
              <p className="fs-5 mt-2">
                <b>Use icons</b> to simplify navigation and reduce clutter.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Takeaways */}
      <Container className="mt-5 mb-5">
        <Row>
          <Col>
            <h1 className="fw-bold mt-5 mb-4">TAKEAWAYS</h1>
            <h3 className="fw-bold">Impact</h3>
            <p className="fs-5">
              The app makes users feel like Wreath is invested and cares about
              their needs, budgets and preferences.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="py-5" style={{ backgroundColor: "#375840" }} fluid>
        <Container>
          <Row className="align-items-center text-light fs-4 justify-content-center">
            <Col>
              <p>
                “The app makes it possible to build your own bouquet! I would
                definitely use this app as a go-to for gifts for myself and
                others!”
              </p>
            </Col>
            <Col lg={4} className="offset-lg-2">
              <Image
                src={QuoteScreen}
                alt="Wreath quote"
                className="img-fluid mt-1"
              />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container>
        <Row>
          <Col>
            <h3 className="fw-bold mt-5">What I learned</h3>
            <p className="fs-5">
              While designing the Wreath app, I learnt to follow effective
              design processes to produce the best possible product for users.
              Usability studies and peer feedback influenced each iteration of
              the app’s designs and paved the way for improvements to be made.
            </p>
          </Col>
        </Row>
      </Container>

      <Container
        className="py-5 mt-5"
        style={{ backgroundColor: "#161616" }}
        fluid
      >
        <Container>
          <Row className="text-center">
            <Col className="text-light">
              <h1 className="fw-bold">GOING FORWARD</h1>
            </Col>
          </Row>
          <Row className="text-center text-light mt-3 justify-content-center">
            <Col>
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#31703E" }}
              >
                1
              </div>
              <p className="fs-5 mt-2 px-4">
                Conduct <b>further usability studies</b> to validate whether the
                pain points users experienced have been effectively addressed.
              </p>
            </Col>
            <Col>
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#31703E" }}
              >
                2
              </div>
              <p className="fs-5 mt-2 px-4">
                Conduct further <b>user research</b> to identify new areas in
                need of improvement and iterations.
              </p>
            </Col>
            <Col>
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#31703E" }}
              >
                3
              </div>
              <p className="fs-5 mt-2 px-4">
                Create opportunity for <b>peer review and feedback</b> sessions.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="mt-5">
        <Row>
          <Col>
            <h1 className="fw-bold mt-5 mb-4">OTHER PROJECTS</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} className="g-0">
            <div
              style={{ backgroundColor: "#B6EBE6" }}
              className="me-0 me-sm-1 rounded-3"
            >
              <a href="/projects/career-assist/">
                <Image
                  src={ProCareerAssist}
                  alt="Project - Career Assist"
                  className="img-fluid"
                />
              </a>
            </div>
          </Col>
          <Col xs={12} sm={6} className="g-0">
            <div
              style={{ backgroundColor: "#FFD499" }}
              className="ms-0 ms-sm-1 mt-2 mt-sm-0 rounded-3"
            >
              <a href="/projects/smallbiz/">
                <Image
                  src={ProSmallbiz}
                  alt="Project - Smallbiz"
                  className="img-fluid"
                />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Wreath;
