import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import ReactDOM from "react-dom";

import CPurple from "../../resources/images/purple-circle.png";
import COrange from "../../resources/images/orange-circle.png";
import CBlue from "../../resources/images/blue-circle.png";
import YtThumbMini from "../components/YtThumbMini";
import EatingPoetryPDF from "./resources/eating-poetry/Eating Poetry.pdf";
import EatingPoetryPPT from "./resources/eating-poetry/Eating Poetry.pptx";
import EatingPoetryExamPDF from "./resources/eating-poetry/Eating Poetry Exam Q&A.pdf";
import EatingPoetryExamPPT from "./resources/eating-poetry/Eating Poetry Exam Q&A.pptx";
import AYoungManPDF from "./resources/a-young-mans-thoughts/a-young-mans-thoughts.pdf";
import AYoungManPPT from "./resources/a-young-mans-thoughts/a-young-mans-thoughts.pptx";
import CagedBirdPDF from "./resources/caged-bird/caged-bird.pdf";
import CagedBirdPPPT from "./resources/caged-bird/caged-bird.pptx";
import SummersDayPDF from "./resources/compare-thee-summers-day/shall-i-compare-thee-to-a-summers-day.pdf";
import SummersDayPPT from "./resources/compare-thee-summers-day/shall-i-compare-thee-to-a-summers-day.pptx";
import FelixRandalPDF from "./resources/felix-randal/Felix Randal.pdf";
import FelixRandalPPT from "./resources/felix-randal/Felix Randal.pptx";
import FelixRandalExamPDF from "./resources/felix-randal/Felix Randal Q&A.pdf";
import FelixRandalExamPPT from "./resources/felix-randal/Felix Randal Q&A.pptx";
import HowDoILoveTheePDF from "./resources/how-do-i-love-thee/How Do I Love Thee.pdf";
import HowDoILoveTheePPT from "./resources/how-do-i-love-thee/How Do I Love Thee.pptx";
import LighthouseKeepersWifePDF from "./resources/lighthouse-keepers-wife/The Lighthouse Keeper’s Wife.pdf";
import LighthouseKeepersWifePPT from "./resources/lighthouse-keepers-wife/The Lighthouse Keeper’s Wife.pptx";
import LiteratureEssayPDF from "./resources/literature-essay/How to write a literature essay.pdf";
import LiteratureEssayPPT from "./resources/literature-essay/How to write a literature essay.pptx";
import ReapersInAMieliefieldPDF from "./resources/reapers-in-a-mieliefield/reapers-in-a-mieliefield.pdf";
import ReapersInAMieliefieldPPT from "./resources/reapers-in-a-mieliefield/reapers-in-a-mieliefield.pptx";
import Sonnet19PDF from "./resources/sonnet-19/Sonnet 19 - John Milton.pdf";
import Sonnet19PPT from "./resources/sonnet-19/Sonnet 19 - John Milton.pptx";
import Sonnet116PDF from "./resources/sonnet-116/Sonnet 116.pdf";
import Sonnet116PPT from "./resources/sonnet-116/Sonnet 116.pptx";
import SpudPDF from "./resources/spud/Spud notes.pdf";
import GardenOfLovePDF from "./resources/the-garden-of-love/The Garden of Love.pdf";
import GardenOfLovePPT from "./resources/the-garden-of-love/The Garden of Love.pptx";
import GardenOfLoveExamPDF from "./resources/the-garden-of-love/The Garden of Love Exam QA.pdf";
import GardenOfLoveExamPPT from "./resources/the-garden-of-love/The Garden of Love Exam QA.pptx";
import TheManPDF from "./resources/the-man/The Man.pdf";
import TheManPPT from "./resources/the-man/The Man.pptx";
import MarkSummariesPDF from "./resources/the-mark/The Mark Chapter Summaries.pdf";
import MarkSummariesPPT from "./resources/the-mark/The Mark Chapter Summaries.pptx";
import MarkCharactersPDF from "./resources/the-mark/The Mark Character Analysis.pdf";
import MarkCharactersPPT from "./resources/the-mark/The Mark Character Analysis.pptx";
import MarkConceptsPDF from "./resources/the-mark/The Mark Important Concepts.pdf";
import MarkConceptsPPT from "./resources/the-mark/The Mark Important Concepts.pptx";
import MarkLitEssayPDF from "./resources/the-mark/The Mark Literature Essay Themes.pdf";
import MarkLitEssayPPT from "./resources/the-mark/The Mark Literature Essay Themes.pptx";
import PalanquinBearersPDF from "./resources/the-palanquin-bearers/Palanquin Bearers.pdf";
import PalanquinBearersPPT from "./resources/the-palanquin-bearers/Palanquin Bearers.pptx";
import ShipwreckPDF from "./resources/the-shipwreck/The Shipwreck.pdf";
import ShipwreckPPT from "./resources/the-shipwreck/The Shipwreck.pptx";
import WarmAndColdPDF from "./resources/the-warm-and-the-cold/the-warm-and-the-cold.pdf";
import WarmAndColdPPT from "./resources/the-warm-and-the-cold/the-warm-and-the-cold.pptx";
import UnseenPoemPDF from "./resources/unseen-poem-analysis/Unseen poem analysis.pdf";
import UnseenPoemPPT from "./resources/unseen-poem-analysis/Unseen poem analysis.pptx";
import WomenPDF from "./resources/women/women.pdf";
import WomenPPT from "./resources/women/women.pptx";
import SummaryPDF from "./resources/writing-a-summary/Writing a summary.pdf";
import SummaryPPT from "./resources/writing-a-summary/Writing a summary.pptx";

const coordinates = [];

function isTooClose(xpos, ypos) {
  for (let i = 0; i < coordinates.length; i++) {
    const prevX = coordinates[i].xpos;
    const prevY = coordinates[i].ypos;

    if (Math.abs(prevX - xpos) <= 170 || Math.abs(prevY - ypos) <= 170) {
      return true;
    }
  }
  return false;
}

function getCoordinates(maxX, maxY) {
  let xpos, ypos;
  for (let i = 0; i < 1000; i++) {
    xpos = Math.floor(Math.random() * maxX);
    ypos = Math.floor(Math.random() * maxY);
    if (!isTooClose(xpos, ypos)) {
      return { xpos, ypos };
    }
  }
  return { xpos, ypos };
}

function getCoordinates2(minX, maxX, maxY) {
  let xpos, ypos;
  for (let i = 0; i < 1000; i++) {
    xpos = minX + Math.floor(Math.random() * (maxX - minX));
    ypos = Math.floor(Math.random() * maxY);
    if (!isTooClose(xpos, ypos)) {
      return { xpos, ypos };
    }
  }
  return { xpos, ypos };
}

function renderCircles() {
  const colours = [CPurple, COrange, CBlue];
  const vw =
    (Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    ) -
      document.getElementById("mainContainer").offsetWidth) /
      2 -
    215;
  var height =
    Math.max(
      window.innerHeight,
      document.documentElement.clientHeight,
      document.body.clientHeight,
    ) - 180;

  const vwMax =
    Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    ) - 215;

  const vwMin = vw + document.getElementById("mainContainer").offsetWidth + 215;

  for (let i = 0; i < 10; i++) {
    const elem = colours[Math.floor(Math.random() * colours.length)];
    const div = document.createElement("div");
    div.classList.add("circle");
    const { xpos, ypos } =
      Math.random() > 0.45
        ? getCoordinates2(vwMin, vwMax, height)
        : getCoordinates(vw, height);
    coordinates.push({ xpos, ypos });
    ReactDOM.render(
      <Image
        container={div}
        src={elem}
        style={{
          transform: `scale(${Math.random()})`,
          position: "absolute",
          top: `${ypos}px`,
          left: `${xpos}px`,
          zIndex: "-10",
        }}
      />,
      document.getElementById("circles").appendChild(div),
      // document.body.appendChild(div)
    );
  }
}

window.addEventListener(
  "load",
  function () {
    if (
      (
        document.documentElement.textContent ||
        document.documentElement.innerText
      ).indexOf("Classroom") > -1
    ) {
      renderCircles();
    }
  },
  false,
);

function Classroom() {
  return (
    <>
      <div id="circles"></div>
      <Container
        id="mainContainer"
        className="mt-3 mt-sm-5"
        style={{ background: "#fff" }}
      >
        <Row className="mb-4">
          <Col>
            <div
              className="rounded mb-3 bg-primary"
              style={{ width: "100px", height: "10px" }}
            ></div>
            <h1 className="fw-bold">Classroom</h1>
          </Col>
          <Col>
            <a className="float-end" href="https://www.buymeacoffee.com/caitlinjessica" target="_blank">
              <Image
                width={217}
                height={60}
                src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png"
                alt="Buy Me A Coffee"
                className="img-fluid"
              />
            </a>
          </Col>
          <Col xs={12}>
            <p className="fs-5">
              Here you'll find resources for teachers and students for various
              English Literature. PowerPoint files are provided for editing
              purposes, but they may not look the same on your computer due to
              missing fonts / resources. If the appearance of the presentation
              is important to you, download the PDF version which will render
              properly on most devices.
            </p>
          </Col>
        </Row>

        <YtThumbMini
          title="A Young Man's Thoughts Before June 16th"
          iframeSrc="https://www.youtube.com/embed/GbX7zGCOk2k"
          resources={[
            {
              name: "A Young Man's Thoughts Before June 16th PDF",
              ref: AYoungManPDF,
            },
            {
              name: "A Young Man's Thoughts Before June 16th PowerPoint",
              ref: AYoungManPPT,
            },
          ]}
        />

        <YtThumbMini
          title="Caged Bird"
          iframeSrc="https://www.youtube.com/embed/QIIS2wJEfG8"
          resources={[
            { name: "Caged Bird PDF", ref: CagedBirdPDF },
            { name: "Caged Bird PowerPoint", ref: CagedBirdPPPT },
          ]}
        />

        <YtThumbMini
          title="Shall I Compare Thee To A Summer's Day?"
          iframeSrc="https://www.youtube.com/embed/HCu4ctb8xMQ"
          resources={[
            {
              name: "Shall I Compare Thee To A Summer's Day PDF",
              ref: SummersDayPDF,
            },
            {
              name: "Shall I Compare Thee To A Summer's Day PowerPoint",
              ref: SummersDayPPT,
            },
          ]}
        />

        <YtThumbMini
          title="Eating Poetry by Mark Strand"
          iframeSrc="https://www.youtube.com/embed/Rwsz87pjmB8"
          resources={[
            { name: "Eating Poetry PDF", ref: EatingPoetryPDF },
            { name: "Eating Poetry PowerPoint", ref: EatingPoetryPPT },
            { name: "Eating Poetry Exam Q&A PDF", ref: EatingPoetryExamPDF },
            {
              name: "Eating Poetry Exam Q&A PowerPoint",
              ref: EatingPoetryExamPPT,
            },
          ]}
        />

        <YtThumbMini
          title="Felix Randal"
          iframeSrc="https://www.youtube.com/embed/0Fr9g99Cr80"
          resources={[
            { name: "Felix Randal PDF", ref: FelixRandalPDF },
            { name: "Felix Randal PowerPoint", ref: FelixRandalPPT },
            { name: "Felix Randal Exam Q&A PDF", ref: FelixRandalExamPDF },
            {
              name: "Felix Randal Exam Q&A PowerPoint",
              ref: FelixRandalExamPPT,
            },
          ]}
        />

        <YtThumbMini
          title="How Do I Love Thee"
          iframeSrc="https://www.youtube.com/embed/nQts8ALKX7M"
          resources={[
            { name: "How Do I Love Thee PDF", ref: HowDoILoveTheePDF },
            { name: "How Do I Love Thee PowerPoint", ref: HowDoILoveTheePPT },
          ]}
        />

        <YtThumbMini
          title="The Lighthouse Keeper's Wife"
          iframeSrc="https://www.youtube.com/embed/ug5ZddiKz00"
          resources={[
            {
              name: "The Lighthouse Keeper's Wife PDF",
              ref: LighthouseKeepersWifePDF,
            },
            {
              name: "The Lighthouse Keeper's Wife PowerPoint",
              ref: LighthouseKeepersWifePPT,
            },
          ]}
        />

        <YtThumbMini
          title="How To Write A Literature Essay"
          iframeSrc="https://www.youtube.com/embed/RGs-Rc59WBY"
          resources={[
            {
              name: "How To Write A Literature Essay PDF",
              ref: LiteratureEssayPDF,
            },
            {
              name: "How To Write A Literature Essay PowerPoint",
              ref: LiteratureEssayPPT,
            },
          ]}
        />

        <YtThumbMini
          title="Reapers In A Mieliefield"
          iframeSrc="https://www.youtube.com/embed/SB5La8LbZqo"
          resources={[
            {
              name: "Reapers In A Mieliefield PDF",
              ref: ReapersInAMieliefieldPDF,
            },
            {
              name: "Reapers In A Mieliefield PowerPoint",
              ref: ReapersInAMieliefieldPPT,
            },
          ]}
        />

        <YtThumbMini
          title="Sonnet 19 - When I Consider How My Light is Spent"
          iframeSrc="https://www.youtube.com/embed/awRV6qBWGEg"
          resources={[
            { name: "Sonnet 19 PDF", ref: Sonnet19PDF },
            { name: "Sonnet 19 PowerPoint", ref: Sonnet19PPT },
          ]}
        />

        <YtThumbMini
          title="Sonnet 116 - Let Me Not To The Marriage Of True Minds"
          iframeSrc="https://www.youtube.com/embed/ubVksdNXUxQ"
          resources={[
            { name: "Sonnet 116 PDF", ref: Sonnet116PDF },
            { name: "Sonnet 116 PowerPoint", ref: Sonnet116PPT },
          ]}
        />

        <YtThumbMini
          title="Spud"
          iframeSrc="https://www.youtube.com/embed/z86pe16x77c"
          resources={[
            { name: "Spud PDF", ref: SpudPDF },
          ]}
        />

        <YtThumbMini
          title="The Garden Of Love"
          iframeSrc="https://www.youtube.com/embed/dXFsfulGTvE"
          resources={[
            { name: "The Garden Of Love PDF", ref: GardenOfLovePDF },
            { name: "Garden Of Love PowerPoint", ref: GardenOfLovePPT },
            { name: "Garden Of Love Exam Q&A PDF", ref: GardenOfLoveExamPDF },
            {
              name: "Garden Of Love Exam Q&A PowerPoint",
              ref: GardenOfLoveExamPPT,
            },
          ]}
        />

        <YtThumbMini
          title="The Man"
          iframeSrc="https://www.youtube.com/embed/151hHNYgNt8"
          resources={[
            { name: "The Man PDF", ref: TheManPDF },
            { name: "The Man PowerPoint", ref: TheManPPT },
          ]}
        />

        <YtThumbMini
          title="The Mark"
          iframeSrc="https://www.youtube.com/embed/7DcRwMU2dj8"
          resources={[
            { name: "The Mark Summaries PDF", ref: MarkSummariesPDF },
            { name: "The Mark Summaries PowerPoint", ref: MarkSummariesPPT },
            { name: "The Mark Characters PDF", ref: MarkCharactersPDF },
            { name: "The Mark Characters PowerPoint", ref: MarkCharactersPPT },
            { name: "The Mark Concepts PDF", ref: MarkConceptsPDF },
            { name: "The Mark Concepts PowerPoint", ref: MarkConceptsPPT },
            {
              name: "The Mark Literature Essay Themes PDF",
              ref: MarkLitEssayPDF,
            },
            {
              name: "The Mark Literature Essay Themes PPT",
              ref: MarkLitEssayPPT,
            },
          ]}
        />

        <YtThumbMini
          title="The Palanquin Bearers"
          iframeSrc="https://www.youtube.com/embed/b0lhgD-M-JI"
          resources={[
            { name: "The Palanquin Bearers PDF", ref: PalanquinBearersPDF },
            {
              name: "The Palanquin Bearers PowerPoint",
              ref: PalanquinBearersPPT,
            },
          ]}
        />

        <YtThumbMini
          title="The Shipwreck"
          iframeSrc="https://www.youtube.com/embed/SdPyCeoJLgE"
          resources={[
            { name: "The Shipwreck PDF", ref: ShipwreckPDF },
            { name: "The Shipwreck PowerPoint", ref: ShipwreckPPT },
          ]}
        />

        <YtThumbMini
          title="The Warm And The Cold"
          iframeSrc="https://www.youtube.com/embed/FjWSVmFQ-MY"
          resources={[
            { name: "The Warm And The Cold PDF", ref: WarmAndColdPDF },
            { name: "The Warm And The Cold PowerPoint", ref: WarmAndColdPPT },
          ]}
        />

        <YtThumbMini
          title="Unseen Poem Analysis"
          iframeSrc="https://www.youtube.com/embed/qEJQmS6meOg"
          resources={[
            { name: "Unseen Poem Analysis PDF", ref: UnseenPoemPDF },
            { name: "Unseen Poem Analysis PowerPoint", ref: UnseenPoemPPT },
          ]}
        />

        <YtThumbMini
          title="Women"
          iframeSrc="https://www.youtube.com/embed/h-SobMwXcNc"
          resources={[
            { name: "Women PDF", ref: WomenPDF },
            { name: "Women PowerPoint", ref: WomenPPT },
          ]}
        />

        <YtThumbMini
          title="How To Write A Summary"
          iframeSrc="https://www.youtube.com/embed/Ad9pnoIP-3M"
          resources={[
            { name: "How To Write A Summary PDF", ref: SummaryPDF },
            { name: "How To Write A Summary PowerPoint", ref: SummaryPPT },
          ]}
        />
      </Container>
    </>
  );
}

export default Classroom;
