import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ListGroup } from "react-bootstrap";

function YtThumbMini(props) {
  const { title, iframeSrc, resources } = props;
  const mappedResources = resources.map((resource) => (
    <ListGroup.Item action href={resource.ref} target="_blank">
      {resource.name} &nbsp;
      <i className="bi bi-download text-primary fw-bold"></i>
    </ListGroup.Item>
  ));
  return (
    <Row className="mb-3 mb-md-4">
      <Col xs={12}>
        <h3 className="fw-bold">{title}</h3>
      </Col>
      <Col md={6} className="mb-2">
        <Row>
          <Col xs={12} className="d-flex justify-content-center">
            <div className="ratio ratio-21x9">
              <iframe
                className="rounded-3"
                src={iframeSrc}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title="YouTube video player"
                allowFullScreen
              ></iframe>
            </div>
          </Col>
        </Row>
      </Col>
      <Col md={6} className="mb-2 mb-sm-3">
        <ListGroup>{mappedResources}</ListGroup>
      </Col>
    </Row>
  );
}

export default YtThumbMini;
