import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import Profile from "../resources/images/contact/profile.png";

function getEmail(e) {
  e.target.href =
    "mailto:" +
    atob(e.target.dataset.name) +
    "@" +
    atob(e.target.dataset.dom) +
    "." +
    atob(e.target.dataset.tld) +
    "?subject=" +
    atob(e.target.dataset.subj || "");
}

function Contact() {
  return (
    <Container className="mt-3 mt-sm-5">
      <Row>
        <Col>
          <div
            className="rounded mb-3 bg-primary"
            style={{ width: "100px", height: "10px" }}
          ></div>
          <h1 className="fw-bold">Contact Me</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="mt-4">
            I would love to hear from you! Send me an email or message on
            Linkedin ... do it! Just do it!
          </h3>
        </Col>
      </Row>
      <Row
        className="mt-3 p-4 rounded-3"
        style={{ backgroundColor: "#F8F8F8" }}
      >
        <Col className="text-center">
          <Row className="justify-content-center">
            <Col className="col-auto">
              <Image
                src={Profile}
                className="img-fluid d-block"
                alt="Profile image"
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <h2 className="text-uppercase fw-bold">Caitlin-Jessica Foster</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>User Experience and Product Designer</h4>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <a
                href="http://www.linkedin.com/in/caitlinjessica"
                rel="noreferrer"
                target="_blank"
                className="text-decoration-none"
              >
                <i className="fs-1 bi bi-linkedin"></i>
                <p className="fs-5 mb-0">LinkedIn Profile</p>
                <p>(Send me a message or invite!)</p>
              </a>
            </Col>
            <Col>
              <a
                href="#"
                data-name="bWU="
                data-dom="Y2FpdGxpbmplc3NpY2E="
                data-tld="Y29t"
                data-subj="Q2FpdGxpbiBKZXNzaWNhIERlc2lnbiBQb3J0Zm9saW8"
                onFocus={getEmail}
                className="text-decoration-none"
              >
                <i className="fs-1 bi bi-envelope-fill"></i>
                <p className="fs-5">Send Me an Email</p>
              </a>
            </Col>
            <Col>
              <a
                href="https://goo.gl/maps/cc4DVViv5WdR8jpe7"
                rel="noreferrer"
                target="_blank"
                className="text-decoration-none"
              >
                <i className="fs-1 bi bi-geo-alt-fill"></i>
                <p className="fs-5">Northern Ireland</p>
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;
