import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Footer() {
  return (
    <Container
      className="bg-dark text-center py-5"
      style={{ marginTop: "150px" }}
      fluid
    >
      <Row>
        <Col className="text-light">
          <span className="fw-bold d-block">Let's Connect</span>
          Feel free to reach out for collaborations or just to say hello!
        </Col>
      </Row>
      <Row className="mt-3 fs-2 justify-content-center">
        <Col className="col-auto">
          <a
            href="https://www.instagram.com/caitlinjessicadesigns/"
            target="_blank"
            rel="noreferrer"
            className="text-light"
          >
            <i className="bi bi-instagram"></i>
          </a>
        </Col>
        <Col className="col-auto">
          <a
            href="https://www.behance.net/caitlinjessica/appreciated"
            target="_blank"
            rel="noreferrer"
            className="text-light"
          >
            <i className="bi bi-behance"></i>
          </a>
        </Col>
        <Col className="col-auto">
          <a
            href="http://www.linkedin.com/in/caitlinjessica"
            rel="noreferrer"
            target="_blank"
            className="text-light"
          >
            <i className="bi bi-linkedin"></i>
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
