import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import Splash from "../../resources/images/smallbiz/splash.png";
import ProjectOverview from "../../resources/images/smallbiz/overview-screen.png";
import Process from "../../resources/images/smallbiz/design-process.png";
import Alice from "../../resources/images/smallbiz/alice.jpg";
import SiteMap from "../../resources/images/smallbiz/site-map.png";
import JourneyMap from "../../resources/images/smallbiz/journey-map.jpg";
import WireframeSplash from "../../resources/images/smallbiz/wireframe-splash.png";
import LofiProto from "../../resources/images/smallbiz/lofi-prototype.png";
import ParamLength from "../../resources/images/smallbiz/parameter-length.jpg";
import ParamStudy from "../../resources/images/smallbiz/parameter-study-type.jpg";
import ParamLocation from "../../resources/images/smallbiz/parameter-location.jpg";
import ParamParticipants from "../../resources/images/smallbiz/parameter-participants.jpg";
import ColourPalette from "../../resources/images/smallbiz/colour-palette.png";
import Typography from "../../resources/images/smallbiz/typography.png";
import Components from "../../resources/images/smallbiz/components.png";
import UsabilityBefore1 from "../../resources/images/smallbiz/before-usability-study-one.png";
import UsabilityBefore2 from "../../resources/images/smallbiz/before-usability-study-two.png";
import UsabilityAfter1 from "../../resources/images/smallbiz/after-usability-study-one.png";
import UsabilityAfter2 from "../../resources/images/smallbiz/after-usability-study-two.png";
import ResponsiveDesign from "../../resources/images/smallbiz/responsive-design-screens.png";
import HiFiProto from "../../resources/images/smallbiz/hifi-prototype.png";
import MockupSplash from "../../resources/images/smallbiz/mockup-splash.png";
import QuoteScreen from "../../resources/images/smallbiz/quote-screen.png";
import ProWreath from "../../resources/images/wreath-op.png";
import ProCareerAssist from "../../resources/images/career-op.png";

function Smallbiz() {
  return (
    <>
      {/* Hero Image */}
      <Container
        fluid
        style={{
          background:
            "rgb(255,212,153) linear-gradient(125deg, rgba(255,212,153,1) 0%, rgba(255,174,62,1) 100%)",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col xs={12}>
              <Image
                src={Splash}
                alt="career-assist splash image"
                className="img-fluid"
                style={{
                  display: "block",
                  margin: "72px auto",
                }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      {/* Project Overview */}
      <Container>
        <Row className="mt-2 pb-5">
          <Col>
            <span className="fw-bold">Project name:</span> SmallBiz
          </Col>
          <Col className="text-end">
            <span className="fw-bold">Tools used:</span> Adobe XD, Affinity
            Designer, Google Workspace
          </Col>
        </Row>
        <Row className="mt-5 pb-5">
          <Col xs={3}>
            <Image
              src={ProjectOverview}
              alt="iPhone with career-assist app"
              className="img-fluid"
            />
          </Col>
          <Col className="offset-1">
            <h1 className="fw-bold">PROJECT OVERVIEW</h1>
            <p className="pt-2 fs-5">
              SmallBiz is a small business directory that connects the public to
              small businesses in their area. The typical user is between 19-60
              years old, and most users are adults or early career
              professionals. SmallBiz’s goal is to create an awareness of small
              businesses amongst the public and to promote shopping local.
            </p>
          </Col>
        </Row>

        <Row className="mt-5 px-2 pb-5">
          <Col
            style={{ backgroundColor: "#47596C" }}
            className="text-light p-4 rounded-3"
          >
            <h3 className="fw-bold">The Problem</h3>
            <p>
              Small businesses are struggling to attract customers due to a lack
              of public exposure throughout the COVID-19 pandemic.
            </p>
          </Col>
          <Col
            style={{ backgroundColor: "#FFD499" }}
            className="p-4 mx-2 rounded-3"
          >
            <h3 className="fw-bold">The Goal</h3>
            <p>
              Design a website that acts as a small business directory that
              connects small businesses with local customers. To increase the
              business’ exposure and attract potential clients.
            </p>
          </Col>
          <Col style={{ backgroundColor: "#B6EBE6" }} className="p-4 rounded-3">
            <h3 className="fw-bold">My Role</h3>
            <p>
              <span className="fw-bold">UX designer </span>
              leading the SmallBiz website design.
            </p>
            <p>
              I was responsible for conducting interviews, paper and digital
              wireframing, low and high-fidelity prototyping, conducting
              usability studies, accounting for accessibility, iterating on
              designs and responsive design.
            </p>
          </Col>
        </Row>

        <Row className="mt-4 pb-5">
          <Col>
            <h3 className="fw-bold">The Design Process</h3>
            <Image
              src={Process}
              alt="The design process"
              className="img-fluid mt-1"
            />
          </Col>
        </Row>
      </Container>
      {/* Understanding the user */}
      <Container
        className="py-5 mt-5"
        style={{ backgroundColor: "#2A3540" }}
        fluid
      >
        <Container className="text-light mt-2 mb-2">
          <Row>
            <Col>
              <h1 className="fw-bold">UNDERSTANDING THE USER</h1>
              <h3 className="fw-bold">User Research Summary</h3>
              <p className="fs-5">
                I conducted numerous user interviews, which I then turned into
                empathy maps to better understand the target user and their
                needs. I discovered that many target users are unaware and
                unable to locate or communicate with the small businesses in
                their community due to a lack of social media presence.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="py-5" style={{ backgroundColor: "#1C232B" }} fluid>
        <Container>
          <Row className="text-center">
            <Col className="text-light">
              <h3>
                <b>User Research: Pain Points</b>
              </h3>
            </Col>
          </Row>
          <Row className="text-center text-light mt-3 justify-content-center">
            <Col>
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#FF9F1C" }}
              >
                1
              </div>
              <h5 className="fw-bold mt-2">Navigation</h5>
              <p className="fs-5">
                Directory website designs are often cluttered and busy, which
                results in confusing navigation and user flow.
              </p>
            </Col>
            <Col>
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#FF9F1C" }}
              >
                2
              </div>
              <h5 className="fw-bold mt-2">Interaction</h5>
              <p className="fs-5">
                Small buttons make item selection difficult, which often leads
                users to make mistakes
              </p>
            </Col>
            <Col>
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#FF9F1C" }}
              >
                3
              </div>
              <h5 className="fw-bold mt-2">Experience</h5>
              <p className="fs-5">
                Directory websites don’t provide an engaging browsing experience
                and lack useful features
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="mb-5">
        <Row>
          <Col>
            <h3 className="fw-bold mt-5 mb-3">Meet Alice</h3>
            <p className="h5 fst-italic">
              “I love the thrill of shopping at small businesses! I just can’t
              seem to find any near me!”
            </p>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col lg={6}>
            <Image
              src={Alice}
              alt="Mary"
              className="img-fluid h-100 rounded-3"
              style={{ objectFit: "none", objectPosition: "center" }}
            />
          </Col>
          <Col lg={6}>
            <div
              className="p-4 rounded-3"
              style={{ backgroundColor: "#FFEBCF" }}
            >
              <h3 className="fw-bold">Problem statement</h3>
              <p className="pt-3">
                <span className="fw-bold">Alice</span> is a busy Graphics
                Designer who needs a website that will direct her to nearby
                small businesses that offer unique products.
              </p>
              <p className="pt-3">
                <span className="fw-bold">Goals</span>
              </p>
              <ul>
                <li>To find and support small businesses in her area.</li>
                <li>
                  To have access to location, contact details and a product
                  list.
                </li>
              </ul>
              <p className="pt-3">
                <span className="fw-bold">Frustrations</span>
              </p>
              <ul>
                <li>
                  “It’s frustrating when small businesses can’t be reached.”
                </li>
                <li>“I don’t know how far away they are.”</li>
                <li>
                  “Finding links to their social media accounts is a nightmare.”
                </li>
              </ul>
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h3 className="fw-bold">User journey map</h3>
            <p className="fs-5">
              I created a user journey map of Alice’s experience using the site
              to help identify possible pain points and improvement
              opportunities.
            </p>
            <p className="fs-5">
              <span className="fw-bold">Goal:</span> To locate and contact small
              businesses in her area with ease.
            </p>
            <Image
              src={JourneyMap}
              alt="Journey map"
              className="img-fluid mt-1"
            />
          </Col>
        </Row>
      </Container>
      {/* Starting the design */}
      <Container>
        <Row>
          <Col>
            <h1 className="fw-bold mt-5 mb-4">STARTING THE DESIGN</h1>
            <h3 className="fw-bold">Sitemap</h3>
            <p className="fs-5">
              Users had difficulty with website navigation. This was a main pain
              point, so I used that knowledge to create a sitemap.
            </p>
            <p className="fs-5">
              My goal was to make strategic information architecture decisions
              that would improve overall website navigation. The structure I
              chose was designed to be simple and easy.
            </p>
          </Col>
        </Row>

        <Row className="mt-5 mb-2 justify-content-center">
          <Col>
            <Image
              src={SiteMap}
              alt="Sitemap of Smallbiz"
              className="img-fluid"
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h3 className="fw-bold">Paper wireframes</h3>
            <p className="fs-5">
              I then sketched paper wireframes for each screen on my website,
              keeping the user pain points about navigation, browsing, and
              checkout flow in mind.
            </p>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h3 className="fw-bold">Digital wireframes </h3>
            <p className="fs-5">
              I then moved from paper to digital wireframes. This made it easy
              to understand how to address user pain points and improve the user
              experience. I chose to prioritize buttons and a single visual
              element placement on the home page.
            </p>
          </Col>
        </Row>
      </Container>
      <Container
        className="mt-5 py-3"
        fluid
        style={{
          backgroundColor: "#98A2AC",
        }}
      >
        <Row className="justify-content-center">
          <Col className="g-0">
            <Image
              src={WireframeSplash}
              alt="career-assist splash image"
              className="img-fluid py-5"
              style={{
                filter: "drop-shadow(10px 10px 7px #555)",
              }}
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-5 align-items-center pb-4">
          <Col lg={6}>
            <h3 className="fw-bold">Low-fidelity prototype</h3>
            <p className="fs-5">
              I created a low-fidelity prototype, by connecting all of the
              screens involved in the primary user flow of messaging a store. I
              implemented several suggestions in places that addressed user pain
              points.
            </p>
          </Col>
          <Col lg={6} className="pt-4 pt-lg-0">
            <Image
              src={LofiProto}
              alt="Low-fidelity prototype"
              className="img-fluid"
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col className="text-dark">
            <h3 className="fw-bold">Usability Study: Parameters</h3>
          </Col>
        </Row>
        <Row className="mt-2 fs-2 justify-content-center">
          <Col>
            <Image
              src={ParamStudy}
              alt="Userbility parameters study type"
              className="img-fluid mt-1 rounded-3"
            />
          </Col>
          <Col>
            <Image
              src={ParamLength}
              alt="Userbility parameters length"
              className="img-fluid mt-1 rounded-3"
            />
          </Col>
          <Col>
            <Image
              src={ParamParticipants}
              alt="Userbility parameters participants"
              className="img-fluid mt-1 rounded-3"
            />
          </Col>
          <Col>
            <Image
              src={ParamLocation}
              alt="Userbility parameters location"
              className="img-fluid mt-1 rounded-3"
            />
          </Col>
        </Row>
      </Container>
      {/* Refining the design */}
      <Container
        className="mt-5"
        fluid
        style={{
          backgroundColor: "#FAFAFA",
        }}
      >
        <Container>
          <Row>
            <Col>
              <h1 className="fw-bold mt-5 mb-4">REFINING THE DESIGN</h1>
              <h3 className="fw-bold">Design System</h3>
              <p className="fs-5">
                To ensure consistency across screens, I developed a design
                system to refer back to while creating my mockups. I chose to
                utilize colour sparingly throughout the app to convey which
                elements were interactable and should be paid attention to
                within the product. I also opted to use the typefaces Bodoni MT
                and Century Gothic within the interface.
              </p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-center pb-5">
            <Col>
              <h3 className="fw-bold mt-4">Colour Palette</h3>
              <Image
                src={ColourPalette}
                alt="Smallbix colour palette"
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container>
        <Row className="justify-content-center">
          <Col>
            <h3 className="fw-bold mt-5">Typography & Buttons</h3>
            <Image
              src={Typography}
              alt="Smallbix Typography"
              className="img-fluid py-3"
            />
          </Col>
        </Row>
      </Container>

      <Container
        className="mt-4 pb-4"
        fluid
        style={{
          backgroundColor: "#FAFAFA",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col>
              <h3 className="fw-bold mt-5">Components</h3>
              <Image
                src={Components}
                alt="Smallbiz components"
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container>
        <Row className="mt-5">
          <Col>
            <h3 className="fw-bold">Mockups</h3>
            <p className="fs-5">
              Based on the insights from the usability study, I made changes to
              improve the site’s navigation flow. One of the changes I made was
              changing the position of the sort by button and added chips to aid
              with searching. This created a less complicated filtering process.
            </p>
            <p className="fs-5">
              To make the navigation flow even easier for users, I added
              categories to search by that allowed users to find relevant stores
              quicker.
            </p>
          </Col>
        </Row>
      </Container>

      <Container
        className="mt-5 pt-5 text-center"
        fluid
        style={{
          backgroundColor: "#F2C75C",
        }}
      >
        <Container>
          <Row className="justify-content-center pb-3">
            <Col>
              <h4 className="fw-bold">Before usability study</h4>
              <Image
                src={UsabilityBefore1}
                alt="Smallbiz usability before"
                className="img-fluid py-3 pe-3"
                style={{
                  filter: "drop-shadow(5px 5px 5px #000)",
                }}
              />
            </Col>
            <Col>
              <h4 className="fw-bold">After usability study</h4>
              <Image
                src={UsabilityAfter1}
                alt="Smallbiz usability after"
                className="img-fluid py-3 ps-3"
                style={{
                  filter: "drop-shadow(5px 5px 5px #000)",
                }}
              />
            </Col>
          </Row>

          <Row className="justify-content-center pt-3 pb-5">
            <Col>
              <Image
                src={UsabilityBefore2}
                alt="Smallbiz usability before"
                className="img-fluid py-3 pe-3"
                style={{
                  filter: "drop-shadow(5px 5px 5px #000)",
                }}
              />
            </Col>
            <Col>
              <Image
                src={UsabilityAfter2}
                alt="Smallbiz usability after"
                className="img-fluid py-3 ps-3"
                style={{
                  filter: "drop-shadow(5px 5px 5px #000)",
                }}
              />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container>
        <Row className="mt-5">
          <Col>
            <h3 className="fw-bold">Screen size variations</h3>
            <p className="fs-5">
              I made considerations for additional screen sizes in my mockups
              based on my earlier wireframes. Users access the website from a
              variety of devices, therefore it was important to optimize the
              browsing experience for a range of device sizes, such as mobile
              and tablet so users have the smoothest experience possible.
            </p>
          </Col>
        </Row>
      </Container>

      <Container
        className="mt-5"
        fluid
        style={{
          backgroundColor: "#FCF1D6",
        }}
      >
        <Container>
          <Row className="justify-content-center py-5">
            <Col>
              <Image
                src={ResponsiveDesign}
                alt="Smallbiz responsive design"
                className="img-fluid py-3"
              />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container>
        <Row className="mt-5 align-items-center pb-4">
          <Col lg={6}>
            <h3 className="fw-bold">High-fidelity prototype</h3>
            <p className="fs-5">
              My hi-fi prototype had the same user flow as the lo-fi prototype,
              and included the design changes made after the usability study.
            </p>
            <a
              href="https://xd.adobe.com/view/65bcf21c-29fe-4985-9bb8-47d758a8a912-30ef/?fullscreen"
              target="_blank"
              rel="noreferrer"
              role="button"
              className="btn btn-lg btn-outline-dark mt-4"
            >
              View HiFi Prototype
            </a>
          </Col>
          <Col lg={6} className="pt-4 pt-lg-0">
            <Image
              src={HiFiProto}
              alt="Hi-fidelity prototype"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>

      <Container
        className="mt-5"
        fluid
        style={{
          backgroundColor: "#657484",
        }}
      >
        <Row className="justify-content-center">
          <Col className="g-0">
            <Image
              src={MockupSplash}
              alt="Smallbiz splash image"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="mt-5">
          <Col>
            <h3 className="fw-bold">Accessibility considerations</h3>
            <p className="fs-5">
              The following accessibility considerations were prioritized to
              ensure a smooth user experience for all users.
            </p>
          </Col>
        </Row>
        <Row className="text-center text-light mt-3 justify-content-center">
          <Col>
            <div
              className="p-4 me-1 rounded-3"
              style={{ backgroundColor: "#2A3540", height: "100%" }}
            >
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#FF9F1C" }}
              >
                1
              </div>
              <h5 className="fw-bold mt-2">Navigation</h5>
              <p className="fs-5">
                Directory website designs are often cluttered and busy, which
                results in confusing navigation and user flow.
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="p-4 rounded-3"
              style={{ backgroundColor: "#2A3540", height: "100%" }}
            >
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#FF9F1C" }}
              >
                2
              </div>
              <h5 className="fw-bold mt-2">Interaction</h5>
              <p className="fs-5">
                Small buttons make item selection difficult, which often leads
                users to make mistakes
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="p-4 ms-1 rounded-3"
              style={{ backgroundColor: "#2A3540", height: "100%" }}
            >
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#FF9F1C" }}
              >
                3
              </div>
              <h5 className="fw-bold mt-2">Experience</h5>
              <p className="fs-5">
                Directory websites don’t provide an engaging browsing experience
                and lack useful features
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Takeaways */}
      <Container className="mt-5 mb-5">
        <Row>
          <Col>
            <h1 className="fw-bold mt-5 mb-4">TAKEAWAYS</h1>
            <h3 className="fw-bold">Impact</h3>
            <p className="fs-5">
              The targeted users shared that the design was intuitive to
              navigate through, more engaging with the large images, and
              demonstrated a clear visual hierarchy.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="py-5" style={{ backgroundColor: "#C3D4EB" }} fluid>
        <Container>
          <Row className="align-items-center justify-content-center text-center">
            <Col>
              <p className="fs-4">
                “Support your local community and shop local #localisbest”
              </p>
            </Col>
            <Col lg={5} className="offset-lg-2">
              <Image
                src={QuoteScreen}
                alt="Smallbiz quote"
                className="img-fluid mt-1"
              />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container>
        <Row>
          <Col>
            <h3 className="fw-bold mt-5">What I learned</h3>
            <p className="fs-5">
              I learned that accessibility in design matters and that design can
              have a huge impact on the user’s experience. I aim to priorotize
              the needs of the user when coming up with design ideas and
              solutions.
            </p>
          </Col>
        </Row>
      </Container>

      <Container
        className="py-5 mt-5 mb-5"
        style={{ backgroundColor: "#1A2D3C" }}
        fluid
      >
        <Container>
          <Row className="text-center">
            <Col className="text-light">
              <h1 className="fw-bold">GOING FORWARD</h1>
            </Col>
          </Row>
          <Row className="text-center text-light mt-3 justify-content-center">
            <Col>
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#FF9F1C" }}
              >
                1
              </div>
              <p className="fs-5 mt-2 px-4">
                Conduct <b>follow-up usability testing</b> on the new website
              </p>
            </Col>
            <Col>
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#FF9F1C" }}
              >
                2
              </div>
              <p className="fs-5 mt-2 px-4">
                <span className="fw-bold">Identify</span> additional areas of
                need and ideate on new features
              </p>
            </Col>
            <Col>
              <div
                className="numberCircle fs-4"
                style={{ backgroundColor: "#FF9F1C" }}
              >
                3
              </div>
              <p className="fs-5 mt-2 px-4">
                Create opportunity for
                <span className="fw-bold">peer review and feedback</span>{" "}
                sessions.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container>
        <Row>
          <Col>
            <h1 className="fw-bold mt-5 mb-4">OTHER PROJECTS</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} className="g-0">
            <div
              style={{ backgroundColor: "#B6EBE6" }}
              className="me-0 me-sm-1 rounded-3"
            >
              <a href="/projects/career-assist/">
                <Image
                  src={ProCareerAssist}
                  alt="Project - Career Assist"
                  className="img-fluid"
                />
              </a>
            </div>
          </Col>
          <Col xs={12} sm={6} className="g-0">
            <div
              style={{ backgroundColor: "#D4EEDD" }}
              className="ms-0 ms-sm-1 mt-2 mt-sm-0 rounded-3"
            >
              <a href="/projects/wreath/">
                <Image
                  src={ProWreath}
                  alt="Project - Wreath"
                  className="img-fluid"
                />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Smallbiz;
