import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function YtThumb(props) {
  const { title, description, iframeSrc } = props;
  return (
    <Row className="mb-4 mb-sm-5">
      <Col lg={4} xl={3}>
        <h2 className="fw-bold">{title}</h2>
        <p className="fs-5">{description}</p>
      </Col>
      <Col lg={8} xl={8} className="d-flex justify-content-center offset-xl-1">
        <div className="ratio ratio-16x9">
          <iframe
            className="rounded-3"
            src={iframeSrc}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title="YouTube video player"
            allowFullScreen
          ></iframe>
        </div>
      </Col>
    </Row>
  );
}

export default YtThumb;
