import React from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import NavBar from "./pages/components/NavBar";
import Page from "./pages/components/Page";
import Footer from "./pages/components/Footer";
import Projects from "./pages/Projects";
import CareerAssist from "./pages/projects/CareerAssist";
import Smallbiz from "./pages/projects/Smallbiz";
import Wreath from "./pages/projects/Wreath";
import About from "./pages/About";
import Contact from "./pages/Contact";
import "./App.css";
import Hydra from "./pages/projects/Hydra";
import Classroom from "./pages/classroom/Classroom";
import NotFound from "./pages/NotFound";

function BasicLayout() {
  return (
    <>
      <NavBar />
      <Outlet />
      <Footer />
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<BasicLayout />}>
          <Route
            index
            element={
              <Page title={"Projects"}>
                <Projects />
              </Page>
            }
          />
          <Route
            path="/about"
            element={
              <Page title={"About"}>
                <About />
              </Page>
            }
          />
          <Route
            path="/contact"
            element={
              <Page title={"Contact"}>
                <Contact />
              </Page>
            }
          />
        </Route>
        <Route path="/projects" element={<BasicLayout />}>
          <Route
            path="hydra"
            element={
              <Page title={"Projects ~ Hydra"}>
                <Hydra />
              </Page>
            }
          />
          <Route
            path="career-assist"
            element={
              <Page title={"Projects ~ Career Assist"}>
                <CareerAssist />
              </Page>
            }
          />
          <Route
            path="wreath"
            element={
              <Page title={"Projects ~ Wreath"}>
                <Wreath />
              </Page>
            }
          />
          <Route
            path="smallbiz"
            element={
              <Page title={"Projects ~ SmallBiz"}>
                <Smallbiz />
              </Page>
            }
          />
        </Route>
        <Route path="/classroom" element={<BasicLayout />}>
          <Route
            index
            element={
              <Page title={"Classroom"}>
                <Classroom />
              </Page>
            }
          />
        </Route>
        <Route
          path="*"
          element={
            <Page title={"404 Not Found"}>
              <NotFound />
            </Page>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
