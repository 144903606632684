import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation } from "react-router-dom";
import Resume from "../../resources/resume.pdf";

import "../../navbar.scss";

function getNavbarShadowClass(location) {
  if (location.pathname.indexOf("projects") === -1) {
    return "nav-no-shadow";
  }
  return "nav-shadow";
}

function NavBar() {
  const location = useLocation();
  return (
    <Navbar expand="md" className={getNavbarShadowClass(location)}>
      <Container>
        <Navbar.Brand href="/">CaitlinJessica</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            activeKey={location.pathname}
            className="justify-content-end"
            style={{ width: "100%" }}
          >
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <NavDropdown title="Projects">
              <NavDropdown.Item as={Link} to="/projects/hydra">
                Hydra
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/projects/career-assist">
                Career Assist
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/projects/smallbiz">
                SmallBiz
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/projects/wreath">
                Wreath
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/about">
              About
            </Nav.Link>
            <Nav.Link as={Link} to={Resume} target="_blank">
              Resume
            </Nav.Link>
            <Nav.Link as={Link} to="/contact">
              Contact
            </Nav.Link>
            <Nav.Link as={Link} to="/classroom">
              Classroom
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
