import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import YtThumb from "./components/YtThumb";
import ReactDOM from "react-dom";

import CaityBear from "../resources/images/about/caity-bear.png";
import CaityRock from "../resources/images/about/caity-rock.png";
import CaitySea from "../resources/images/about/caity-sea.png";
import CaityTim from "../resources/images/about/caity-tim.png";

import ArtBeetle from "../resources/images/about/art-beetle.png";
import ArtCaity from "../resources/images/about/art-caity.png";
import ArtMan from "../resources/images/about/art-man.png";
import ArtTwiggy from "../resources/images/about/art-twiggy.png";

import Laugh from "../resources/images/about/laugh.jpg";
import Frida from "../resources/images/about/frida.jpg";
import Boat from "../resources/images/about/boat.jpg";
import Madiba from "../resources/images/about/madiba.jpg";
import Nature from "../resources/images/about/nature.jpg";
import Pugs from "../resources/images/about/pugs.jpg";
import Trinity from "../resources/images/about/trinity.jpg";

import CPurple from "../resources/images/purple-circle.png";
import COrange from "../resources/images/orange-circle.png";
import CBlue from "../resources/images/blue-circle.png";

const coordinates = [];

function isTooClose(xpos, ypos) {
  for (let i = 0; i < coordinates.length; i++) {
    const prevX = coordinates[i].xpos;
    const prevY = coordinates[i].ypos;

    if (Math.abs(prevX - xpos) <= 170 || Math.abs(prevY - ypos) <= 170) {
      return true;
    }
  }
  return false;
}

function getCoordinates(maxX, maxY) {
  let xpos, ypos;
  for (let i = 0; i < 1000; i++) {
    xpos = Math.floor(Math.random() * maxX);
    ypos = Math.floor(Math.random() * maxY);
    if (!isTooClose(xpos, ypos)) {
      return { xpos, ypos };
    }
  }
  return { xpos, ypos };
}

function getCoordinates2(minX, maxX, maxY) {
  let xpos, ypos;
  for (let i = 0; i < 1000; i++) {
    xpos = minX + Math.floor(Math.random() * (maxX - minX));
    ypos = Math.floor(Math.random() * maxY);
    if (!isTooClose(xpos, ypos)) {
      return { xpos, ypos };
    }
  }
  return { xpos, ypos };
}

function renderCircles() {
  const colours = [CPurple, COrange, CBlue];
  const vw =
    (Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    ) -
      document.getElementById("mainContainer").offsetWidth) /
      2 -
    215;
  var height =
    Math.max(
      window.innerHeight,
      document.documentElement.clientHeight,
      document.body.clientHeight,
    ) - 180;

  const vwMax =
    Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    ) - 215;

  const vwMin = vw + document.getElementById("mainContainer").offsetWidth + 215;

  for (let i = 0; i < 5; i++) {
    const elem = colours[Math.floor(Math.random() * colours.length)];
    const div = document.createElement("div");
    div.classList.add("circle");
    const { xpos, ypos } =
      Math.random() > 0.45
        ? getCoordinates2(vwMin, vwMax, height)
        : getCoordinates(vw, height);
    coordinates.push({ xpos, ypos });
    ReactDOM.render(
      <Image
        container={div}
        src={elem}
        style={{
          transform: `scale(${Math.random()})`,
          position: "absolute",
          top: `${ypos}px`,
          left: `${xpos}px`,
          zIndex: "-10",
        }}
      />,
      document.getElementById("circles").appendChild(div),
      // document.body.appendChild(div)
    );
  }
}

window.addEventListener(
  "load",
  function () {
    if (
      (
        document.documentElement.textContent ||
        document.documentElement.innerText
      ).indexOf("404 Not Found") > -1
    ) {
      renderCircles();
    }
  },
  false,
);

function NotFound() {
  return (
    <>
      <div id="circles"></div>
      <Container
        id="mainContainer"
        className="mt-3 mt-sm-5"
        style={{ background: "#fff" }}
      >
        <Row>
          <Col>
            <div
              className="rounded mb-3 bg-primary"
              style={{ width: "100px", height: "10px" }}
            ></div>
            <h1 className="fw-bold">404 Not Found</h1>
          </Col>
        </Row>

        <Row className="mt-3 mb-4 mb-sm-5">
          <Col>
            <Image
              src={CaityRock}
              className="img-fluid rounded-3"
              alt="Caity on a rock"
            />
          </Col>
          <Col>
            <h3>
              Oh no! You're about as stuck as I was on the way down in this
              photo ↩️
            </h3>
            <p>Why don't you try one of these links?</p>
            <a
              href="http://www.linkedin.com/in/caitlinjessica"
              rel="noreferrer"
              className="text-decoration-none"
              style={{
                color: "#000",
              }}
            >
              <i className="fs-1 bi bi-linkedin"></i>
              <p className="fs-5 mb-0">LinkedIn Profile</p>
              <p>Send me a message or invite!</p>
            </a>
            <a
              href="http://caitlinjessica.com"
              rel="noreferrer"
              className="text-decoration-none"
              style={{
                color: "#000",
              }}
            >
              <i className="fs-1 bi bi-image-fill"></i>
              <p className="fs-5 mb-0">Portfolio</p>
              <p>View my portfolio</p>
            </a>
            <a
              href="http://caitlinjessica.com/classroom"
              rel="noreferrer"
              className="text-decoration-none"
              style={{
                color: "#000",
              }}
            >
              <i className="fs-1 bi bi-vector-pen"></i>
              <p className="fs-5 mb-0">Classroom</p>
              <p>Visit my classroom & teaching resources</p>
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default NotFound;
