import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import Splash from "../../resources/images/hydra/splash.png";
import Logo from "../../resources/images/hydra/hydra-logo.png";
import ProjectOverview from "../../resources/images/hydra/hydra-image1.png";
import HydraDashboard from "../../resources/images/hydra/hydra-image2.png";
import Block1 from "../../resources/images/hydra/block-1.png";
import Block2 from "../../resources/images/hydra/block-2.png";
import Block3 from "../../resources/images/hydra/block-3.png";
import Block4 from "../../resources/images/hydra/block-4.png";
import Process from "../../resources/images/hydra/process.png";
import BlockReport from "../../resources/images/hydra/block-report.png";
import BlockPortal from "../../resources/images/hydra/block-portal.png";
import BlockDashboard from "../../resources/images/hydra/block-dashboard.png";
import ProSmallbiz from "../../resources/images/smallbiz-op.png";
import ProCareerAssist from "../../resources/images/career-op.png";

function Hydra() {
  return (
    <>
      {/* Hero Image */}
      <Container
        className="py-3"
        fluid
        style={{
          background:
            "rgb(11,48,16) linear-gradient(125deg, rgba(11,48,163,1) 0%, rgba(2,20,75,1) 100%)",
        }}
      >
        <Container>
          <Row className="py-4 align-items-center">
            <Col xs={6}>
              <Image
                src={Splash}
                alt="hydra splash image"
                className="img-fluid"
                style={{
                  display: "block",
                  margin: "72px auto",
                }}
              />
            </Col>
            <Col xs={6}>
              <Row>
                <Col>
                  <Image
                    src={Logo}
                    alt="hydra logo"
                    className="img-fluid"
                    style={{
                      display: "block",
                      margin: "0 auto",
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="text-light">
                  <p className="text-center lead">
                    As the nature of this work is confidential, I won’t be able
                    to show any project visuals for the time being. However, I
                    can provide context and an outline of the processes I’ve
                    implemented while working on Hydra.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* Project Overview */}
      <Container>
        <Row className="mt-2 pb-5">
          <Col>
            <span>
              <b>Client:</b> TopUp Consultants
            </span>
            <span className="ps-3">
              <b>Project name:</b> Hydra
            </span>
          </Col>
          <Col className="text-end">
            <span className="fw-bold">Tools used:</span> Figma, Affinity
            Designer
          </Col>
        </Row>
        <Row className="mt-5 pb-5">
          <Col>
            <h1 className="fw-bold">PROJECT OVERVIEW</h1>
            <p className="pt-2 fs-5">
              Hydra is an all-in-one property management platform that provides
              a range of tools that property managers and retail owners can use
              to effectively manage their properties. Hydra’s platform
              integrates with and pulls data from industry standard retail
              systems such as MRI and Yardi, enabling users to access
              information and the financial details of their properties all in
              one space.
            </p>
          </Col>
          <Col xs={6} className="offset-1">
            <Image
              src={ProjectOverview}
              alt="Hydra connections"
              className="img-fluid"
            />
          </Col>
        </Row>
        <Row className="mt-3 pb-5">
          <Col xs={6}>
            <Image
              src={HydraDashboard}
              alt="Hydra dashboard"
              className="img-fluid"
            />
          </Col>
          <Col>
            <p className="pt-2 fs-5 offset-1">
              My role as a UX designer was to pioneer the research and design of
              innovative features and functionality such as custom data
              visualization in the form of dashboards, reporting, and brandable
              tenant and manager portals.{" "}
            </p>
          </Col>
        </Row>
      </Container>

      {/* Key Challenges */}
      <Container className="mt-5">
        <Row>
          <Col>
            <h1 className="fw-bold">KEY CHALLENGES</h1>
          </Col>
        </Row>
        <Row className="mt-4 px-2 pb-5">
          <Col className="p-3 rounded-3 shadow">
            <Row className="align-items-center justify-content-start">
              <Col className="pe-0 col-sm-auto">
                <Image src={Block1} alt="1" />
              </Col>
              <Col>
                <h4 className="fw-bold m-0">Restrictive</h4>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <p>
                  Users reported that the initial reporting functionality was
                  limited and too restrictive. It did not allow them to deviate
                  from the template provided. Furthermore they were unable to
                  style reports to suite their brand.
                </p>
              </Col>
            </Row>
          </Col>
          <Col className="p-3 ms-2 rounded-3 shadow">
            <Row className="align-items-center justify-content-start">
              <Col className="pe-0 col-sm-auto">
                <Image src={Block2} alt="2" />
              </Col>
              <Col>
                <h4 className="fw-bold m-0">Disconnected</h4>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <p>
                  Users had to open numerous other applications and systems to
                  view the analytics and performance metrics of their
                  properties.
                </p>
              </Col>
            </Row>
          </Col>
          <Col className="p-3 mx-2 rounded-3 shadow">
            <Row className="align-items-center justify-content-start">
              <Col className="pe-0 col-sm-auto">
                <Image src={Block3} alt="3" />
              </Col>
              <Col>
                <h4 className="fw-bold m-0">Unmanageable</h4>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <p>
                  Users were unable to keep track of the communication that
                  takes between various stakeholders as it takes place on
                  numerous different platforms through multiple channels.
                </p>
              </Col>
            </Row>
          </Col>
          <Col className="p-3 rounded-3 shadow">
            <Row className="align-items-center justify-content-start">
              <Col className="pe-0 col-sm-auto">
                <Image
                  src={Block4}
                  style={{ display: "inline", float: "left" }}
                  alt="4"
                />
              </Col>
              <Col>
                <h4 className="fw-bold m-0">Complex</h4>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <p>
                  Property managers and tenants disliked that tasks, processes
                  and requests relating to the property were
                  administratively-heavy and took place offline or via phone
                  calls, paper forms and emails.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {/* My Process */}
      <Container className="mt-4">
        <Row>
          <Col>
            <h1 className="fw-bold mt-5">MY PROCESS</h1>
          </Col>
        </Row>
        <Row className="mt-4 justify-content-center">
          <Col
            className="p-5 shadow-sm"
            xs={12}
            style={{ backgroundColor: "#F3F5F9", borderRadius: "1rem" }}
          >
            <Image
              src={Process}
              alt="User centered design"
              className="img-fluid"
              style={{
                display: "block",
                margin: "0 auto",
              }}
            />
          </Col>
        </Row>
      </Container>

      {/* Solutions */}
      <Container className="mt-5">
        <Row>
          <Col>
            <h1 className="fw-bold mt-5">SOLUTIONS</h1>
          </Col>
        </Row>
        <Row className="mt-4 px-2 pb-5">
          <Col className="p-4 rounded-3 shadow">
            <Row>
              <Col>
                <Image
                  src={BlockReport}
                  alt="Hydra connections"
                  className="img-fluid mx-auto d-block"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p>
                  <b>Customisable reports</b> that enable property managers to
                  generate and share brand-specific customisable reports in a
                  variety of formats.
                </p>
              </Col>
            </Row>
          </Col>
          <Col className="p-4 mx-2 rounded-3 shadow">
            <Row>
              <Col>
                <Image
                  src={BlockDashboard}
                  alt="Hydra connections"
                  className="img-fluid mx-auto d-block"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p>
                  <b>Intuitive dashboards</b> that showcase key property &
                  financial metrics. Data will be collected from multiple
                  sources (MRI, Yardi, Salesforce and Sage) and showcased on a
                  single dashboard .
                </p>
              </Col>
            </Row>
          </Col>
          <Col className="p-4 rounded-3 shadow">
            <Row>
              <Col>
                <Image
                  src={BlockPortal}
                  alt="Hydra connections"
                  className="img-fluid mx-auto d-block"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p>
                  <b>Specialised portals</b> that provide stakeholders with
                  access to relevant features and information that suite their
                  specific needs.{" "}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {/* Other Projects */}
      <Container className="mt-5">
        <Row>
          <Col>
            <h1 className="fw-bold mt-5 mb-4">OTHER PROJECTS</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} className="g-0">
            <div
              style={{ backgroundColor: "#B6EBE6" }}
              className="me-0 me-sm-1 rounded-3"
            >
              <a href="/projects/career-assist/">
                <Image
                  src={ProCareerAssist}
                  alt="Project - Career Assist"
                  className="img-fluid"
                />
              </a>
            </div>
          </Col>
          <Col xs={12} sm={6} className="g-0">
            <div
              style={{ backgroundColor: "#FFD499" }}
              className="ms-0 ms-sm-1 mt-2 mt-sm-0 rounded-3"
            >
              <a href="/projects/smallbiz/">
                <Image
                  src={ProSmallbiz}
                  alt="Project - Smallbiz"
                  className="img-fluid"
                />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Hydra;
