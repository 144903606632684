import React from "react";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ProHydra from "../resources/images/home/circle-hydra.png";
import ProCareerAssist from "../resources/images/circle-career.png";
import ProWreath from "../resources/images/circle-wreath.png";
import ProSmallBiz from "../resources/images/circle-smallbiz.png";
import Splash from "../resources/images/splash.jpg";
import CPurple from "../resources/images/purple-circle.png";
import COrange from "../resources/images/orange-circle.png";
import CBlue from "../resources/images/blue-circle.png";

function Projects() {
  return (
    <>
      {/* Scattered circles */}
      <Image
        src={CPurple}
        style={{
          transform: "scale(0.5)",
          position: "absolute",
          top: "110%",
          right: "20px",
          zIndex: "-10",
        }}
      />

      <Image
        src={CBlue}
        style={{
          transform: "scale(0.6)",
          position: "absolute",
          top: "170%",
          left: "-100px",
          zIndex: "-10",
        }}
      />

      <Image
        src={COrange}
        style={{
          transform: "scale(1)",
          position: "absolute",
          top: "190%",
          right: "100px",
          zIndex: "-10",
        }}
      />

      <Image
        src={CPurple}
        style={{
          transform: "scale(0.5)",
          position: "absolute",
          top: "250%",
          right: "200px",
          zIndex: "-10",
        }}
      />

      <Image
        src={CBlue}
        style={{
          transform: "scale(0.6)",
          position: "absolute",
          top: "240%",
          left: "700px",
          zIndex: "-10",
        }}
      />

      <Image
        src={COrange}
        style={{
          transform: "scale(0.6)",
          position: "absolute",
          top: "330%",
          left: "0",
          zIndex: "-10",
        }}
      />

      <Image
        src={CPurple}
        style={{
          transform: "scale(0.4)",
          position: "absolute",
          top: "390%",
          left: "300px",
          zIndex: "-10",
        }}
      />

      <Image
        src={CBlue}
        style={{
          transform: "scale(0.8)",
          position: "absolute",
          top: "340%",
          right: "100px",
          zIndex: "-10",
        }}
      />

      {/* Header and splash */}
      <section
        style={{
          minHeight: "calc(100vh - 72px)",
          height: "calc(100vh - 72px)",
          display: "flex",
          alignItems: "center",
          background: `#fff url(${Splash})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom center",
        }}
      >
        <Container>
          <Row>
            <Col xs={7}>
              <h1 className="display-2 mb-0">Hi there!</h1>
              <h1 className="display-1 fw-bold">I'm Caitlin-Jessica</h1>
              <p className="fs-4 mt-1">
                A passionate UX designer who believes in using creative design
                solutions to meet the needs of all users, solve complex
                problems, and help make the world a bit more colourful! 😃
              </p>
              <a
                href="/about"
                role="button"
                className="btn btn-lg btn-primary mb-5 rounded-pill mt-1"
              >
                More about me <i className="bi bi-arrow-right"></i>
              </a>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Hydra */}
      <Container>
        <Row className="align-items-center">
          <Col>
            <a href="/projects/hydra/">
              <Image
                src={ProHydra}
                className="img-fluid"
                alt="Project - Hydra"
              />
            </a>
          </Col>
          <Col xs={5} className="offset-1">
            <div
              className="rounded mb-3"
              style={{ width: "100px", height: "10px", background: "#4465C8" }}
            ></div>
            <h1 className="display-4 mb-0">Project 01</h1>
            <a
              href="/projects/hydra/"
              className="text-decoration-none hydra-link"
            >
              <h1 className="display-2 fw-bold">
                Hydra <i className="bi bi-arrow-right"></i>
              </h1>
            </a>
            <p className="fs-5">
              An all-in-one property management platform that provides a range
              of tools to effectively manage properties and real estate
              businesses.
            </p>
          </Col>
        </Row>
      </Container>

      {/* Wreath */}
      <Container className="mt-5">
        <Row className="align-items-center">
          <Col xs={5}>
            <div
              className="rounded mb-3"
              style={{ width: "100px", height: "10px", background: "#31703E" }}
            ></div>
            <h1 className="display-4 mb-0">Project 02</h1>
            <a
              href="/projects/wreath/"
              className="text-decoration-none wreath-link"
            >
              <h1 className="display-2 fw-bold">
                Wreath <i className="bi bi-arrow-right"></i>
              </h1>
            </a>
            <p className="fs-5">
              Offering users the ability to easily order beautiful, affordable
              and personalised bouquets to suit any budget.
            </p>
          </Col>
          <Col className="offset-1">
            <a href="/projects/wreath/">
              <Image
                src={ProWreath}
                className="img-fluid"
                alt="Project - Wreath"
              />
            </a>
          </Col>
        </Row>
      </Container>

      {/* Career Assist */}
      <Container>
        <Row className="align-items-center">
          <Col>
            <a href="/projects/career-assist/">
              <Image
                src={ProCareerAssist}
                className="img-fluid"
                alt="Project - Career Assist"
              />
            </a>
          </Col>
          <Col xs={5} className="offset-1">
            <div
              className="rounded mb-3"
              style={{ width: "100px", height: "10px", background: "#147AA1" }}
            ></div>
            <h1 className="display-4 mb-0">Project 03</h1>
            <a
              href="/projects/career-assist/"
              className="text-decoration-none career-assist-link"
            >
              <h1 className="display-2 fw-bold">
                Career Assist <i className="bi bi-arrow-right"></i>
              </h1>
            </a>
            <p className="fs-5">
              Providing users with the necessary tools and information needed to
              make informed career decisions.
            </p>
          </Col>
        </Row>
      </Container>

      {/* SmallBiz */}
      <Container>
        <Row className="align-items-center">
          <Col xs={5}>
            <div
              className="rounded mb-3"
              style={{ width: "100px", height: "10px", background: "#FFBE18" }}
            ></div>
            <h1 className="display-4 mb-0">Project 04</h1>
            <a
              href="/projects/smallbiz/"
              className="text-decoration-none smallbiz-link"
            >
              <h1 className="display-2 fw-bold">
                SmallBiz <i className="bi bi-arrow-right"></i>
              </h1>
            </a>
            <p className="fs-5">
              Connecting the public to small businesses in their area.
            </p>
          </Col>
          <Col className="offset-1">
            <a href="/projects/smallbiz/">
              <Image
                src={ProSmallBiz}
                className="img-fluid"
                alt="Project - SmallBiz"
              />
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Projects;
