import { useEffect } from "react";

const Page = (props) => {
  useEffect(() => {
    document.title = `Caitlin Jessica ~  ${props.title}` || "Caitlin Jessica";
  }, [props.title]);
  return props.children;
};

export default Page;
