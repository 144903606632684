import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import Splash from "../../resources/images/career-assist/splash.png";
import IPhone1 from "../../resources/images/career-assist/iphone1.png";
import Process from "../../resources/images/career-assist/double-diamond.png";
import Mary from "../../resources/images/career-assist/mary.jpg";
import Luke from "../../resources/images/career-assist/luke.jpg";
import CompetitiveAudit from "../../resources/images/career-assist/competitive-audit.png";
import Ideation from "../../resources/images/career-assist/ideation.jpg";
import WfDashboard from "../../resources/images/career-assist/wireframe-dashboard.png";
import WfPersonality from "../../resources/images/career-assist/wireframe-personality.png";
import WfResults from "../../resources/images/career-assist/wireframe-results.png";
import WfTests from "../../resources/images/career-assist/wireframe-tests.png";
import LofiProto from "../../resources/images/career-assist/lofi-proto.png";
import ParamLength from "../../resources/images/career-assist/parameters-length.png";
import ParamStudy from "../../resources/images/career-assist/parameters-study-type.png";
import ParamLocation from "../../resources/images/career-assist/parameters-location.png";
import ParamParticipants from "../../resources/images/career-assist/parameters-participants.png";
import TypoChart from "../../resources/images/career-assist/typography-chart.png";
import ColourPalette from "../../resources/images/career-assist/colour-palette.png";
import ComponentChart from "../../resources/images/career-assist/component-chart.png";
import UserbilityOldResults from "../../resources/images/career-assist/iphone-old-results-screen.png";
import UserbilityNewResults from "../../resources/images/career-assist/iphone-new-results-screen.png";
import UserbilityOldTest from "../../resources/images/career-assist/iphone-old-test-screen.png";
import UserbilityNewTest from "../../resources/images/career-assist/iphone-new-test-screen.png";
import IPhoneHifi from "../../resources/images/career-assist/iphone-hifi.png";
import HiFiGroupTop from "../../resources/images/career-assist/iphone-hifi-group-top.png";
import HiFiGroupBtm from "../../resources/images/career-assist/iphone-hifi-group-bottom.png";
import SiteMap from "../../resources/images/career-assist/sitemap-chart.png";
import ResponsiveWeb from "../../resources/images/career-assist/responsive-web-screen-sizes.png";
import IPhoneImpact from "../../resources/images/career-assist/iphone-impact.png";
import ProWreath from "../../resources/images/wreath-op.png";
import ProSmallBiz from "../../resources/images/smallbiz-op.png";

function CareerAssist() {
  return (
    <>
      <Container
        fluid
        style={{
          background:
            "rgb(215,237,234) linear-gradient(125deg, rgba(215,237,234,1) 0%, rgba(139,232,220,1) 100%)",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col xs={12}>
              <Image
                src={Splash}
                alt="career-assist splash image"
                className="img-fluid"
                style={{
                  display: "block",
                  margin: "72px auto",
                  filter: "drop-shadow(20px 20px 15px #444)",
                }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Row className="mt-2 pb-3 pb-sm-5">
          <Col>
            <p className="px-2 px-sm-0">
              <span className="fw-bold">Project name:</span> Career Assist
            </p>
          </Col>
          <Col className="text-end">
            <p className="px-2 px-sm-0">
              <span className="fw-bold">Tools used:</span> Figma, Affinity
              Designer, Google Workspace
            </p>
          </Col>
        </Row>
        <Row className="mt-2 mt-sm-5 pb-2 pb-sm-5">
          <Col xs={12} sm={3} className="mt-2 mt-sm-0">
            <Image
              src={IPhone1}
              alt="iPhone with career-assist app"
              style={{
                filter: "drop-shadow(10px 10px 10px #999)",
              }}
              className="img-fluid"
            />
          </Col>
          <Col className="offset-sm-1 order-first order-sm-last">
            <h1 className="fw-bold px-2 px-sm-0">PROJECT OVERVIEW</h1>
            <p className="pt-2 fs-5 px-2 px-sm-0">
              Career Assist is an App that offers career guidance and advice to
              individuals preparing to choose a career and enter the workplace.
              Through user research, I have found that individuals are excited
              to start their careers, but are confused as to which career path
              is most suitable for them.
            </p>
          </Col>
        </Row>

        <Row className="mt-4 mt-sm-5 pb-4 pb-sm-5 ">
          <Col xs={12} lg={4}>
            <div
              className="text-dark p-3 p-sm-4 rounded-3"
              style={{ backgroundColor: "#F4939F", height: "100%" }}
            >
              <h3 className="fw-bold">The Problem</h3>
              <p>
                Choosing a suitable career can be difficult and frustrating. 44%
                of Students in the United Kingdom don't know which career to
                pursue after graduation.
              </p>
            </div>
          </Col>
          <Col xs={12} lg={4} className="mt-1 mt-lg-0">
            <div
              className="p-3 p-sm-4 rounded-3"
              style={{ backgroundColor: "#FFD499", height: "100%" }}
            >
              <h3 className="fw-bold ">The Goal</h3>
              <p>
                To help users make an informed decision about their career by
                designing an app that guides users through making a career
                choice by providing relevant information and helping them
                understand their personalities, interests, aptitudes, and values
                better.
              </p>
            </div>
          </Col>
          <Col xs={12} lg={4} className="mt-1 mt-lg-0">
            <div
              className="p-3 p-sm-4 rounded-3"
              style={{ backgroundColor: "#B6EBE6", height: "100%" }}
            >
              <h3 className="fw-bold">My Role</h3>
              <p>
                <span className="fw-bold">UX designer </span>
                leading the app and responsive website design from conception to
                delivery.
              </p>
              <p>
                I was responsible for conducting use cases, initiating
                interviews, creating paper and digital wireframing, low and
                high-fidelity prototyping, conducting usability studies,
                accounting for accessibility, iterating on designs, determining
                information architecture, and responsive design.
              </p>
            </div>
          </Col>
        </Row>

        <Row className="mt-2 mt-sm-4 pb-2 pb-sm-5">
          <Col>
            <h3 className="fw-bold px-2 px-sm-0">The Design Process</h3>
            <Image
              src={Process}
              alt="The design process"
              className="img-fluid mt-1 px-2 px-sm-0"
            />
          </Col>
        </Row>
      </Container>
      <Container
        className="py-4 py-sm-5 mt-4 mt-sm-5"
        style={{ backgroundColor: "#011627" }}
        fluid
      >
        <Container className="text-light mt-2 mb-sm-2">
          <Row>
            <Col>
              <h1 className="fw-bold px-2 px-sm-0">UNDERSTANDING THE USER</h1>
              <h3 className="fw-bold px-2 px-sm-0">User Research Summary</h3>
              <p className="fs-5 px-2 px-sm-0">
                I used Career Assist’s data on participating in personality
                tests to develop interview questions, which were then used to
                conduct user interviews. Most interview participants reported
                feeling uncertain and confused about selecting the most suitable
                career, but did not know what steps to take in order to make an
                informed decision. The feedback received made it very clear that
                users would feel more confident choosing a course or career path
                to pursue, if they had access to an easy-to-use tool to help
                guide them.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container
        className="py-4 py-sm-5"
        style={{ backgroundColor: "#1A2D3C" }}
        fluid
      >
        <Container>
          <Row className="text-center">
            <Col className="text-light">
              <h3 className="px-2 px-sm-0">
                <b>The Problems Users Face When Choosing A Suitable Career</b>
              </h3>
            </Col>
          </Row>
          <Row className="text-center text-light mt-3 justify-content-center">
            <Col>
              <div
                className="numberCircle fs-3"
                style={{ backgroundColor: "#2EC4B6" }}
              >
                1
              </div>
              <p className="fs-5 mt-1 mt-sm-2 px-4">Lack of self-awareness</p>
            </Col>
            <Col className="mt-3 mt-sm-0">
              <div
                className="numberCircle fs-3"
                style={{ backgroundColor: "#2EC4B6" }}
              >
                2
              </div>
              <p className="fs-5 mt-1 mt-sm-2 px-4">Unexplored opportunities</p>
            </Col>
            <Col className="mt-3 mt-sm-0">
              <div
                className="numberCircle fs-3"
                style={{ backgroundColor: "#2EC4B6" }}
              >
                3
              </div>
              <p className="fs-5 mt-1 mt-sm-2 px-4">
                Limited knowledge of careers
              </p>
            </Col>
            <Col className="mt-3 mt-sm-0">
              <div
                className="numberCircle fs-3"
                style={{ backgroundColor: "#2EC4B6" }}
              >
                4
              </div>
              <p className="fs-5 mt-1 mt-sm-2 px-4">Knowing where to start</p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Row>
          <Col>
            <h3 className="fw-bold mt-4 mt-sm-5 mb-3 px-2 px-sm-0">
              Meet Mary
            </h3>
            <p className="h5 fst-italic px-2 px-sm-0">
              “I don’t know which career to pursue. I feel as if there is too
              much pressure to know what I need to study!”
            </p>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col lg={6}>
            <Image
              src={Mary}
              alt="Mary"
              className="img-fluid h-100 rounded-3"
              style={{ objectFit: "none", objectPosition: "center" }}
            />
          </Col>
          <Col lg={6}>
            <div
              className="p-4 rounded-3"
              style={{ backgroundColor: "#B6EBE6" }}
            >
              <h3 className="fw-bold">Problem statement</h3>
              <p>
                <span className="fw-bold">Mary</span> is a high school student
                who needs career guidance and coaching to make an informed
                decision because they want to pursue a suitable career path.
              </p>
              <p>
                <span className="fw-bold">Goals</span>
              </p>
              <ul>
                <li>To find a suitable career path to follow.</li>
                <li>
                  To discover available options after completing high school.
                </li>
                <li>
                  To know which career opportunities are linked with certain
                  qualifications.
                </li>
              </ul>
              <p>
                <span className="fw-bold">Frustrations</span>
              </p>
              <ul>
                <li>
                  “It’s impossible to choose the right career so early in life.”
                </li>
                <li>“I feel pressured to choose the right qualification.”</li>
                <li>“I have no idea what to study or what my options are.”</li>
              </ul>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <h3 className="fw-bold mt-5 mb-3">Meet Luke</h3>
            <p className="h5 fst-italic">
              “I love art and illustration but my parents say there are no
              proper careers in art.”
            </p>
          </Col>
        </Row>

        <Row className="mt-3 pb-3">
          <Col lg={6}>
            <Image
              src={Luke}
              alt="Luke"
              className="img-fluid h-100 rounded-3"
              style={{ objectFit: "none", objectPosition: "20% 20%" }}
            />
          </Col>
          <Col lg={6}>
            <div
              className="p-4 rounded-3"
              style={{ backgroundColor: "#B6EBE6" }}
            >
              <h3 className="fw-bold">Problem statement</h3>
              <p>
                <span className="fw-bold">Luke</span> is a busy high school
                student who needs a service that provides career guidance and
                helpful information, because they want to choose the right
                career path.
              </p>
              <p>
                <span className="fw-bold">Goals</span>
              </p>
              <ul>
                <li>To find a suitable qualification to study.</li>
                <li>
                  To discover viable career paths in art and illustration.
                </li>
              </ul>
              <p>
                <span className="fw-bold">Frustrations</span>
              </p>
              <ul>
                <li>
                  “I feel pressured to choose a career my parents approve of and
                  I can’t find information to prove my case.”
                </li>
                <li>“I have no idea what to study or what my options are.”</li>
              </ul>
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h3 className="fw-bold">Competitive Audit</h3>
            <p className="fs-5">
              An audit of a few competitor’s service provided valuable direction
              and guidance on gaps and opportunities needed to address with the
              Career Assist app.
            </p>
            <Image
              src={CompetitiveAudit}
              alt="Competitive Audit"
              className="img-fluid mt-1 rounded-3"
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h3 className="fw-bold">Ideation</h3>
            <p className="fs-5">
              I did a quick <span className="fw-bold">Crazy Eight </span>
              exercise to come up with ideas for how to address gaps identified
              in the competitive audit. My focus was specifically on
              personality, interests, aptitude and values tests.
            </p>
            <Image src={Ideation} alt="Ideation" className="img-fluid mt-1" />
          </Col>
        </Row>
      </Container>
      <Container
        className="pb-5 px-5 mt-5"
        style={{ backgroundColor: "#B6EBE6" }}
        fluid
      >
        <Container>
          <Row>
            <Col>
              <h1 className="fw-bold mt-5 mb-4">STARTING THE DESIGN</h1>
              <h3 className="fw-bold">Digital wireframes</h3>
              <p className="fs-5">
                Once the paper wireframes were ideated and drafted, I began
                creating the initial designs for the Career Assist app. These
                digital designs focused on delivering personalized guidance to
                users to help choose a suitable career path.
              </p>
            </Col>
          </Row>

          <Row className="text-light mt-5 mb-2 fs-2 justify-content-center">
            <Col xs={3}>
              <Image
                src={WfDashboard}
                alt="Wireframe of Career Assist dashboard"
                style={{
                  filter: "drop-shadow(5px 5px 5px #333)",
                }}
                className="img-fluid"
              />
            </Col>
            <Col xs={3}>
              <Image
                src={WfPersonality}
                alt="Wireframe of Career Assist personality test"
                className="img-fluid"
                style={{
                  filter: "drop-shadow(5px 5px 5px #333)",
                }}
              />
            </Col>
            <Col xs={3}>
              <Image
                src={WfResults}
                alt="Wireframe of Career Assist results page"
                className="img-fluid"
                style={{
                  filter: "drop-shadow(5px 5px 5px #333)",
                }}
              />
            </Col>
            <Col xs={3}>
              <Image
                src={WfTests}
                alt="Wireframe of Career Assist test overview page"
                className="img-fluid"
                style={{
                  filter: "drop-shadow(5px 5px 5px #333)",
                }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Row className="mt-5 align-items-center pb-4">
          <Col lg={6}>
            <h3 className="fw-bold">Low-fidelity prototype</h3>
            <p className="fs-5">
              To prepare for usability testing, I created a low-fidelity
              prototype that tested the user flow of performing a personality
              assessment and viewing results.
            </p>
            <a
              href="https://www.figma.com/proto/UleXxkvzgq0F8SDdl5Gd74/Career-Assist?page-id=0%3A1&node-id=2%3A173&viewport=241%2C48%2C0.29&scaling=scale-down&starting-point-node-id=2%3A173"
              target="_blank"
              rel="noreferrer"
              role="button"
              className="btn btn-lg btn-outline-dark mt-4"
            >
              View LoFi Prototype
            </a>
          </Col>
          <Col lg={6} className="pt-4 pt-lg-0">
            <Image
              src={LofiProto}
              alt="Low-fidelity prototype"
              className="img-fluid rounded-3"
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col className="text-dark">
            <h3 className="fw-bold">Usability Study: Parameters</h3>
          </Col>
        </Row>
        <Row className="mt-2 fs-2 justify-content-center">
          <Col>
            <Image
              src={ParamStudy}
              alt="Userbility parameters study type"
              className="img-fluid mt-1 rounded-3"
            />
          </Col>
          <Col>
            <Image
              src={ParamLength}
              alt="Userbility parameters length"
              className="img-fluid mt-1 rounded-3"
            />
          </Col>
          <Col>
            <Image
              src={ParamParticipants}
              alt="Userbility parameters participants"
              className="img-fluid mt-1 rounded-3"
            />
          </Col>
          <Col>
            <Image
              src={ParamLocation}
              alt="Userbility parameters location"
              className="img-fluid mt-1 rounded-3"
            />
          </Col>
        </Row>
      </Container>
      <Container
        className="text-center py-5 mt-5"
        style={{ backgroundColor: "#011627" }}
        fluid
      >
        <Container>
          <Row>
            <Col className="text-light">
              <h3 className="fw-bold">
                These were the main findings uncovered by the usability study
              </h3>
            </Col>
          </Row>
          <Row className="text-center text-light mt-3 justify-content-center">
            <Col xs={6} sm={4}>
              <div
                className="numberCircle fs-3"
                style={{ backgroundColor: "#2EC4B6" }}
              >
                1
              </div>
              <h5 className="fw-bold mt-2">Images too small</h5>
              <p className="fs-5 mt-2 px-lg-4">
                Users want larger images and icons.
              </p>
            </Col>
            <Col xs={6} sm={4}>
              <div
                className="numberCircle fs-3"
                style={{ backgroundColor: "#2EC4B6" }}
              >
                2
              </div>
              <h5 className="fw-bold mt-2">Back button</h5>
              <p className="fs-5 mt-2 px-lg-4">
                Users found the position of the back button to be unusual and
                difficult to find.
              </p>
            </Col>
            <Col xs={12} sm={4}>
              <div
                className="numberCircle fs-3"
                style={{ backgroundColor: "#2EC4B6" }}
              >
                3
              </div>
              <h5 className="fw-bold mt-2">Checklist</h5>
              <p className="fs-5 mt-2 px-lg-4">
                Users want easy access to a checklist that lays out which step
                to take next when choosing a career path.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Row>
          <Col>
            <h1 className="fw-bold mt-5 mb-4">REFINING THE DESIGN</h1>
            <h3 className="fw-bold">Design System</h3>
            <p className="fs-5">
              To ensure consistency across screens, I developed a design system
              to refer back to while creating my mockups. I chose to utilize
              colour sparingly throughout the app to convey which elements were
              interactable and should be paid attention to within the product. I
              also opted to use the typefaces Roboto and Poppins within the
              interface.
            </p>
          </Col>
        </Row>

        <Row className="mt-4 fs-2 justify-content-center">
          <Col>
            <div className="pe-1">
              <h3 className="fw-bold">Palette</h3>
              <Image
                src={ColourPalette}
                alt="Design system colour palette"
                className="img-fluid mt-1"
              />
            </div>
          </Col>
          <Col>
            <div className="ps-1">
              <h3 className="fw-bold">Typography</h3>
              <Image
                src={TypoChart}
                alt="Design system typography"
                className="img-fluid mt-1"
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Container
        className="py-5 mt-5"
        style={{ backgroundColor: "#F9F9F9" }}
        fluid
      >
        <Container>
          <Row>
            <Col>
              <h3 className="fw-bold">Components</h3>
            </Col>
          </Row>
          <Row className="text-light fs-2 justify-content-center">
            <Col className="col-auto">
              <Image
                src={ComponentChart}
                alt="Design system Component Chart"
                className="img-fluid mt-1"
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="py-5" style={{ backgroundColor: "#DEF6F3" }} fluid>
        <Container>
          <Row>
            <Col>
              <h3 className="fw-bold">Mockups</h3>
              <p className="fs-5">
                Based on the insights from the usability studies, I applied
                design changes, for example increasing the size of images and
                icons for a better user experience. Additional design changes
                included repositioning the “back” button to a more obvious
                place.
              </p>
            </Col>
          </Row>
          <Row className="mt-4 text-dark fs-2 justify-content-center">
            <Col>
              <h5 className="fw-bold">Before usability study</h5>
            </Col>
            <Col className="offset-lg-1">
              <h5 className="fw-bold">After usability study</h5>
            </Col>
          </Row>

          <Row className="mt-2 text-center text-dark fs-2 justify-content-center">
            <Col>
              <Image
                src={UserbilityOldTest}
                alt="Userbility findings"
                className="img-fluid mt-1"
                style={{
                  filter: "drop-shadow(10px 10px 7px #444)",
                }}
              />
            </Col>
            <Col>
              <Image
                src={UserbilityOldResults}
                alt="Userbility findings"
                className="img-fluid mt-1"
                style={{
                  filter: "drop-shadow(10px 10px 7px #444)",
                }}
              />
            </Col>
            <Col className="offset-lg-1">
              <Image
                src={UserbilityNewTest}
                alt="Userbility findings"
                className="img-fluid mt-1"
                style={{
                  filter: "drop-shadow(10px 10px 7px #444)",
                }}
              />
            </Col>
            <Col>
              <Image
                src={UserbilityNewResults}
                alt="Userbility findings"
                className="img-fluid mt-1"
                style={{
                  filter: "drop-shadow(10px 10px 7px #444)",
                }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="mt-5 pt-3 pb-5">
        <Row className="align-items-center">
          <Col>
            <h3 className="fw-bold">High-fidelity prototype</h3>
            <p className="fs-5">
              The high-fidelity prototype followed the same user flow as the
              low-fidelity prototype, including design changes made after the
              usability study.
            </p>
            <a
              href="https://www.figma.com/proto/qzme5jUrtmzagoDZjZoXvO/Career-Assist-(HiFi)-Prototype?page-id=0%3A1&node-id=2%3A173&viewport=241%2C48%2C0.89&scaling=scale-down&starting-point-node-id=2%3A173"
              target="_blank"
              rel="noreferrer"
              role="button"
              className="btn btn-lg btn-outline-dark mt-4"
            >
              View HiFi Prototype
            </a>
          </Col>
          <Col xs={4} className="offset-lg-1">
            <Image
              src={IPhoneHifi}
              alt="iPhone with career-assist app (HiFi)"
              style={{
                filter: "drop-shadow(10px 10px 10px #999)",
              }}
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
      <Container
        className="text-center py-5 px-5 mt-3"
        style={{ backgroundColor: "#FFD499" }}
        fluid
      >
        <Row className="text-light mt-3 fs-2 justify-content-center">
          <Col>
            <Image
              src={HiFiGroupTop}
              alt="HiFi rendition of career assist app"
              style={{
                filter: "drop-shadow(5px 5px 5px #333)",
              }}
              className="img-fluid"
            />
          </Col>
        </Row>
        <Row className="text-light mt-5 fs-2 justify-content-center">
          <Col>
            <Image
              src={HiFiGroupBtm}
              alt="HiFi rendition of career assist app"
              className="img-fluid"
              style={{
                filter: "drop-shadow(5px 5px 5px #333)",
              }}
            />
          </Col>
        </Row>
      </Container>
      <Container className="mt-5">
        <Row>
          <Col>
            <h3 className="fw-bold">Accessibility considerations</h3>
            <p className="fs-5">
              The following accessibility considerations were prioritized to
              ensure a smooth user experience for all users.
            </p>
          </Col>
        </Row>
        <Row className="mt-4 text-center text-light justify-content-center">
          <Col
            className="p-3 p-lg-5 rounded-3"
            style={{ backgroundColor: "#011627" }}
          >
            <div
              className="numberCircle fs-3"
              style={{ backgroundColor: "#2EC4B6" }}
            >
              1
            </div>
            <p className="fs-5 mt-2 px-4">
              <b>Clear labels</b> for interactive elements that can be read by
              screen readers.
            </p>
          </Col>
          <Col
            className="p-3 p-lg-5 ms-md-3 mt-1 mt-md-0 rounded-3"
            style={{ backgroundColor: "#011627" }}
          >
            <div
              className="numberCircle fs-3"
              style={{ backgroundColor: "#2EC4B6" }}
            >
              2
            </div>
            <p className="fs-5 mt-2 px-4">
              Initial focus of the home screen on{" "}
              <b>personalized recommendations</b> to help define the primary
              task or action for the user.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <h1 className="fw-bold mt-5 mb-4 pt-5">RESPONSIVE DESIGN</h1>
            <h3 className="fw-bold">Sitemap</h3>
            <p className="fs-5">
              With the app designs completed, I started working on designing the
              responsive website. I used the Career Assist sitemap to guide the
              organizational structure of each screen’s design to ensure a
              cohesive and consistent experience across devices.
            </p>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5" fluid>
        <Row className="justify-content-center">
          <Col className="col-auto">
            <Image
              src={SiteMap}
              alt="Career assist Sitemap"
              style={{
                filter: "drop-shadow(3px 3px 3px #ccc)",
              }}
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
      <Container className="mt-5" style={{ backgroundColor: "#F9F9F9" }} fluid>
        <Container>
          <Row className="py-5 justify-content-center align-items-center">
            <Col lg={7}>
              <Image
                src={ResponsiveWeb}
                alt="Responsive to screen size"
                style={{
                  filter: "drop-shadow(5px 5px 5px #333)",
                }}
                className="img-fluid"
              />
            </Col>
            <Col>
              <h3 className="fw-bold">Responsive designs</h3>
              <p className="fs-5">
                The designs for screen size variation included mobile, tablet,
                and desktop. I optimized the designs to fit specific user needs
                of each device and screen size.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container
        className="text-light py-5"
        style={{ backgroundColor: "#011627" }}
        fluid
      >
        <Container>
          <Row>
            <Col>
              <h1 className="fw-bold mb-4">TAKEAWAYS</h1>
              <h3 className="fw-bold">Impact</h3>
              <p className="fs-5">
                Users shared that the Career Assist app made selecting a
                suitable career seem like something they could actually do. One
                quote from user feedback was that:
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="py-5" style={{ backgroundColor: "#1A2D3C" }} fluid>
        <Container>
          <Row className="align-items-center text-light fs-4 justify-content-center">
            <Col>
              <p>
                “The Career Assist app helps young people feel more confident
                when making such a big life decision, it provides guidance and
                offers advice that so many people need to make an informed
                decision .”
              </p>
            </Col>
            <Col lg={4} className="offset-lg-2">
              <Image
                src={IPhoneImpact}
                alt="Userbility findings"
                className="img-fluid mt-1"
                style={{
                  filter: "drop-shadow(20px 20px 15px #000B13)",
                }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="py-5" style={{ backgroundColor: "#F1F1F1" }} fluid>
        <Container>
          <Row className="text-dark">
            <Col>
              <h3 className="fw-bold">What I learned</h3>
              <p className="fs-5">
                I learned that even though the problem I was trying to solve was
                a big one, diligently going through each step of the design
                process and aligning with specific user needs helped me come up
                with solutions that were both feasible and useful.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Row>
          <Col>
            <h1 className="fw-bold mt-5 mb-4">GOING FORWARD</h1>
          </Col>
        </Row>
        <Row className="text-center mt-3 justify-content-center mb-5">
          <Col
            className="p-4 me-1 me-md-0 rounded-3"
            style={{ backgroundColor: "#DEF6F3" }}
          >
            <div
              className="numberCircle fs-3"
              style={{ backgroundColor: "#2EC4B6" }}
            >
              1
            </div>
            <p className="fs-5 mt-2 px-lg-4">
              <b>Conduct research</b> on how successful the app is in reaching
              the goal to help users select a suitable career path.
            </p>
          </Col>
          <Col
            className="p-4 mx-md-3 rounded-3"
            style={{ backgroundColor: "#DEF6F3" }}
          >
            <div
              className="numberCircle fs-3"
              style={{ backgroundColor: "#2EC4B6" }}
            >
              2
            </div>
            <p className="fs-5 mt-2 px-lg-4">
              Add more <b>educational resources</b> for users to learn about
              career choices.
            </p>
          </Col>
          <Col
            className="p-4 mt-1 mt-md-0 rounded-3"
            style={{ backgroundColor: "#DEF6F3" }}
          >
            <div
              className="numberCircle fs-3"
              style={{ backgroundColor: "#2EC4B6" }}
            >
              3
            </div>
            <p className="fs-5 mt-2 px-lg-4">
              <b>Provide information</b> on “in-demand” careers.
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <h1 className="fw-bold mt-5 mb-4">OTHER PROJECTS</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} className="g-0">
            <div
              style={{ backgroundColor: "#D4EEDD" }}
              className="me-0 me-sm-1 rounded-3"
            >
              <a href="/projects/wreath/">
                <Image
                  src={ProWreath}
                  alt="Project - Wreath"
                  className="img-fluid"
                />
              </a>
            </div>
          </Col>
          <Col xs={12} sm={6} className="g-0">
            <div
              style={{ backgroundColor: "#FFD499" }}
              className="ms-0 ms-sm-1 mt-2 mt-sm-0 rounded-3"
            >
              <a href="/projects/smallbiz/">
                <Image
                  src={ProSmallBiz}
                  alt="Project - Smallbiz"
                  className="img-fluid"
                />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CareerAssist;
