import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import YtThumb from "./components/YtThumb";
import ReactDOM from "react-dom";

import CaityBear from "../resources/images/about/caity-bear.png";
import CaityRock from "../resources/images/about/caity-rock.png";
import CaitySea from "../resources/images/about/caity-sea.png";
import CaityTim from "../resources/images/about/caity-tim.png";

import ArtBeetle from "../resources/images/about/art-beetle.png";
import ArtCaity from "../resources/images/about/art-caity.png";
import ArtMan from "../resources/images/about/art-man.png";
import ArtTwiggy from "../resources/images/about/art-twiggy.png";

import Laugh from "../resources/images/about/laugh.jpg";
import Frida from "../resources/images/about/frida.jpg";
import Boat from "../resources/images/about/boat.jpg";
import Madiba from "../resources/images/about/madiba.jpg";
import Nature from "../resources/images/about/nature.jpg";
import Pugs from "../resources/images/about/pugs.jpg";
import Trinity from "../resources/images/about/trinity.jpg";

import CPurple from "../resources/images/purple-circle.png";
import COrange from "../resources/images/orange-circle.png";
import CBlue from "../resources/images/blue-circle.png";

const coordinates = [];

function isTooClose(xpos, ypos) {
  for (let i = 0; i < coordinates.length; i++) {
    const prevX = coordinates[i].xpos;
    const prevY = coordinates[i].ypos;

    if (Math.abs(prevX - xpos) <= 170 || Math.abs(prevY - ypos) <= 170) {
      return true;
    }
  }
  return false;
}

function getCoordinates(maxX, maxY) {
  let xpos, ypos;
  for (let i = 0; i < 1000; i++) {
    xpos = Math.floor(Math.random() * maxX);
    ypos = Math.floor(Math.random() * maxY);
    if (!isTooClose(xpos, ypos)) {
      return { xpos, ypos };
    }
  }
  return { xpos, ypos };
}

function getCoordinates2(minX, maxX, maxY) {
  let xpos, ypos;
  for (let i = 0; i < 1000; i++) {
    xpos = minX + Math.floor(Math.random() * (maxX - minX));
    ypos = Math.floor(Math.random() * maxY);
    if (!isTooClose(xpos, ypos)) {
      return { xpos, ypos };
    }
  }
  return { xpos, ypos };
}

function renderCircles() {
  const colours = [CPurple, COrange, CBlue];
  const vw =
    (Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    ) -
      document.getElementById("mainContainer").offsetWidth) /
      2 -
    215;
  var height =
    Math.max(
      window.innerHeight,
      document.documentElement.clientHeight,
      document.body.clientHeight,
    ) - 180;

  const vwMax =
    Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    ) - 215;

  const vwMin = vw + document.getElementById("mainContainer").offsetWidth + 215;

  for (let i = 0; i < 50; i++) {
    const elem = colours[Math.floor(Math.random() * colours.length)];
    const div = document.createElement("div");
    div.classList.add("circle");
    const { xpos, ypos } =
      Math.random() > 0.45
        ? getCoordinates2(vwMin, vwMax, height)
        : getCoordinates(vw, height);
    coordinates.push({ xpos, ypos });
    ReactDOM.render(
      <Image
        container={div}
        src={elem}
        style={{
          transform: `scale(${Math.random()})`,
          position: "absolute",
          top: `${ypos}px`,
          left: `${xpos}px`,
          zIndex: "-10",
        }}
      />,
      document.getElementById("circles").appendChild(div),
      // document.body.appendChild(div)
    );
  }
}

window.addEventListener(
  "load",
  function () {
    if (
      (
        document.documentElement.textContent ||
        document.documentElement.innerText
      ).indexOf("About Me") > -1
    ) {
      renderCircles();
    }
  },
  false,
);

function About() {
  return (
    <>
      <div id="circles"></div>
      <Container
        id="mainContainer"
        className="mt-3 mt-sm-5"
        style={{ background: "#fff" }}
      >
        <Row>
          <Col>
            <div
              className="rounded mb-3 bg-primary"
              style={{ width: "100px", height: "10px" }}
            ></div>
            <h1 className="fw-bold">About Me</h1>
          </Col>
        </Row>

        <Row className="mt-3 mb-4 mb-sm-5">
          <Col>
            <Image
              src={CaityBear}
              className="img-fluid rounded-3"
              alt="Caity & Polar bear rock"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className="fw-bold">
              I am Caitlin-Jessica, a UX designer based in Northern Ireland.
            </h3>
            <p className="fs-5">
              Before entering the UX Design field, my passion for art and people
              led me to become a high school teacher . I had the opportunity to
              share my passions with others and teach valuable skills to future
              generations. Even though I loved teaching and empathising with my
              students, a career in design had always been my heart’s desire.
            </p>
          </Col>
        </Row>

        <Row className="mt-4 mt-sm-5">
          <Col xs={12} md={6}>
            <Image
              src={CaitySea}
              className="img-fluid rounded-3"
              alt="Caity by the sea"
            />
          </Col>
          <Col xs={12} md={6} className="pt-2 pt-md-0">
            <Image
              src={CaityRock}
              className="img-fluid rounded-3"
              alt="Caity climbing"
            />
          </Col>
        </Row>

        <Row className="mt-4 mt-sm-5">
          <Col>
            <p className="fs-5">
              I believe that the skills, experiences and insights I gained from
              being a teacher, make me a better designer. I am able to emphasize
              with users and provide solutions that meet their needs and address
              their pain points while ensuring functionality and visual
              aesthetics.
            </p>
            <p className="fs-5">
              The world is constantly evolving and designers are tasked with
              creating useful and effective products to meet the needs of all
              users. My design process starts with understanding what the user
              needs, and ideating an innovative solution to fulfill it. I
              embrace diversity and differences with an inclusionary vision.
              Every user matters - I care about equity and accessibility,
              everyone should be cared for and included regardless of their
              background, ethnicity, race, and class.
            </p>
          </Col>
        </Row>

        <Row className="mt-4 mt-sm-5">
          <Col>
            <Image
              src={CaityTim}
              className="img-fluid rounded-3"
              alt="Caity & Tim"
            />
          </Col>
        </Row>

        <Row className="mt-4 mt-sm-5 pt-5">
          <Col>
            <div
              className="rounded mb-3"
              style={{
                width: "100px",
                height: "10px",
                backgroundColor: "#F5CE35",
              }}
            ></div>
            <h1 className="fw-bold">Artworks</h1>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <p className="fs-5">
              I also make art! Here are some of my creations:
            </p>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs={5}>
            <div className="card">
              <Image
                src={ArtTwiggy}
                className="img-fluid rounded-3"
                alt="Twiggy"
              />
            </div>
            <div className="card mt-3">
              <Image src={Pugs} className="img-fluid rounded-3" alt="Pugs" />
            </div>

            <div className="card mt-3">
              <Image src={Boat} className="img-fluid rounded-3" alt="Boat" />
            </div>

            <div className="card mt-3">
              <Image
                src={Trinity}
                className="img-fluid rounded-3"
                alt="Trinity"
              />
            </div>
          </Col>
          <Col xs={7}>
            <Row>
              <Col>
                <div className="card">
                  <Image
                    src={ArtCaity}
                    className="img-fluid rounded-3"
                    alt="Caity"
                  />
                </div>
              </Col>
              <Col>
                <div className="card">
                  <Image
                    src={ArtMan}
                    className="img-fluid rounded-3"
                    alt="Coffee man"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <div className="card">
                  <Image
                    src={ArtBeetle}
                    className="img-fluid rounded-3"
                    alt="Rustic beetle"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={8}>
                <div className="card">
                  <Image
                    src={Madiba}
                    className="img-fluid rounded-3"
                    alt="Madiba"
                  />
                </div>
              </Col>
              <Col xs={4}>
                <div className="card">
                  <Image
                    src={Frida}
                    className="img-fluid rounded-3"
                    alt="Frida"
                  />
                </div>
                <div className="card mt-3">
                  <Image
                    src={Nature}
                    className="img-fluid rounded-3"
                    alt="Nature"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="pt-0 pt-sm-5 mt-4 mt-sm-5">
          <Col>
            <div
              className="rounded mb-3"
              style={{
                width: "100px",
                height: "10px",
                backgroundColor: "#0FAAEF",
              }}
            ></div>
            <h1 className="fw-bold">YouTube Channel</h1>
          </Col>
        </Row>

        <Row className="mt-3 mb-3">
          <Col>
            <p className="fs-5">
              In my spare time, I make art, poetry and literature videos. Feel
              free to look at some of my work below!
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <YtThumb
              title="Eating Poetry by Mark Strand"
              description="This video provides an in depth analysis of the poem “Eating Poetry” by Mark Strand."
              iframeSrc="https://www.youtube.com/embed/Rwsz87pjmB8"
            />
          </Col>
        </Row>

        <YtThumb
          title="The Garden of Love by William Blake"
          description="This video discusses meaning and characteristics of the poem."
          iframeSrc="https://www.youtube.com/embed/dXFsfulGTvE"
        />

        <YtThumb
          title="The Palanquin Bearers by Sarojini Naidu"
          description="This video explores various features of the poem."
          iframeSrc="https://www.youtube.com/embed/b0lhgD-M-JI"
        />

        <YtThumb
          title="How Do I Love Thee by Elizabeth Browning"
          description="This video analyses various aspects of the poem."
          iframeSrc="https://www.youtube.com/embed/nQts8ALKX7M"
        />
      </Container>
    </>
  );
}

export default About;
